import { SampleModel, UserInfoModel } from '@app/models';
import { Action, createReducer, on, Store } from '@ngrx/store';
import * as AppActions from './app.actions';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
export const APP_STATE = 'appState';

export interface IApp {
    //sampleService: SampleModel[],
    userInfo: UserInfoModel,
    application: any;
    states: any[],
    country: any[],
    naicsCodes: any[],
    entityList: any[],
    appId: number,
    lastSavedRoute: [],
    lobType: string
}

export const initialAppState: IApp = {
    //sampleService: [],
    userInfo: {} as UserInfoModel,
    application: {} as any,
    states: [],
    country: [],
    naicsCodes: [],
    entityList: [],
    appId: 0,
    lastSavedRoute: [] as any,
    lobType: '' as string
};

export const reducer = createReducer(
    initialAppState as IApp,
    on(AppActions.sampleServiceSuccess, (state, { todos }) => ({
        ...state,
        sampleService: todos,
    })),
    on(AppActions.userInfoSuccess, (state, { userInfo }) => ({
        ...state,
        userInfo: userInfo,
    })),
    on(AppActions.getBsaProfileSuccess, (state, { application }) => ({
        ...state,
        application: application
    })),
    on(AppActions.getStatesSuccess, (state, { states }) => ({
        ...state,
        states: states
    })),
    on(AppActions.getCountriesSuccess, (state, { countries }) => ({
        ...state,
        country: countries
    })),
    on(AppActions.loadNaicsCodesSuccess, (state, { naicsCodes }) => ({
        ...state,
        naicsCodes: naicsCodes
    })),
    on(AppActions.getEntityListSuccess, (state, { entityList }) => ({
        ...state,
        entityList: entityList
    })),
    on(AppActions.appId, (state, { appId }) => ({
        ...state,
        appId: appId
    })),
    on(AppActions.getLastSavedRoute, (state, { lastSavedRoute }) => ({
        ...state,
        lastSavedRoute: lastSavedRoute
    })),
    on(AppActions.updateBsaProfile, (state, { application }) => ({
        ...state,
        application: application
    })),
    //Adding Action to test the component store. Dont remove this
    on(AppActions.updateComponentState, (state, { componentName, componentState }) => ({
        ...state,
        [componentName]: { ...componentState }
    })),
    on(AppActions.lobType, (state, { lobType }) => ({
        ...state,
        lobType: lobType
    })),
)



export function appReducer(state: IApp | undefined, action: Action): IApp {
    return reducer(state as IApp, action as Action);
}

//View State Dev Tools

export const initialState: any = {};

export const componentStateReducer = createReducer(
    initialState,
    on(AppActions.updateComponentState, (state, { componentName, componentState }) => {
        return ({ [componentName]: { ...componentState } });
    })
);


export const linkToGlobalState = (componentState$: Observable<any>, componentName: string, globalStore: Store) => {
    componentState$.pipe(
        distinctUntilChanged((prev, next) => isEqual(prev, next))
    ).subscribe(componentState => {
        globalStore.dispatch(AppActions.updateComponentState({ componentName, componentState }));
    });
};

