import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  logotopnav = new Subject();

  constructor( private toast: ToastController) { }

  async success(msg) {
    const toast = await this.toast.create({
      header: `${msg}`,
      icon: 'checkmark-circle-outline',
      color: 'success',
      position: 'bottom',
      duration: 6000,
      cssClass: 'toast-custom-class',
    });
    toast.present();
  }

  async error(msg) {
    const toast = await this.toast.create({
      message: msg,
      icon: 'close-circle-outline',
      position: 'bottom',
      color: 'danger',
      duration: 6000,
      cssClass: 'toast-custom-class',
    });
    toast.present();
  }
}
