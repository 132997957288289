import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, take } from 'rxjs';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiHttpService {

  apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient
  ) {}

  public getMock = <T>(url: string, options?: any): Observable<any> => this.http.get(url, options).pipe(take(1));
  public get = <T>(url: string, options?: any): Observable<any> => this.http.get(this.apiUrl + url, options).pipe(take(1));
  public post = <T>(url: string, data: any, options?: any): Observable<any> => this.http.post(this.apiUrl + url, data, options).pipe(take(1));
  public put = <T>(url: string, data: any, options?: any): Observable<any> => this.http.put(this.apiUrl + url, data, options).pipe(take(1));
  public delete = <T>(url: string, options?: any): Observable<any> => this.http.delete(this.apiUrl + url, options).pipe(take(1));
  public patch = <T>(url: string, options?: any): Observable<any> => this.http.patch(this.apiUrl + url, options).pipe(take(1));
  public postNotification = (url: string, data: any, options?: any): Observable<any> => this.http.post('https://commercialapi-qa.texascapitalbank.com/api-proxy/notification', data, options).pipe(take(1));
}
