export enum ApplicationFlow {
    businessProfile = 'business-profile',
    geography = 'geography',
    regulatoryLegal = 'regulatory-and-legal',
    MBS_TBA = 'mbs-tba',
    securities ='securities',
    estateRelatedQuestions = 'estate-related-questions',
    trustRelatedQuestions = 'trust-related-questions',
    additionalQuestions = 'additional-business-questions',
    additionalProfiles = 'additional-profile',
    products = 'products',
    activity = 'activity',
    documents = 'documents',
    review = 'review',
    success = 'success',
    application = 'application',
    preapplication = 'preapplication',
    ultimateBeneficialOwnership = 'ultimateBeneficialOwnership',
    trustSettlorsQuestions = 'trust-settlor-questions'
}
//High Risk Industries
export const highRiskIndustriesValues: any = {
  'SQC-40000-1': ['Privately owned automated teller machines (ATM)'],
  businessExceptionCategory: ['Arms/Weapons manufactorer and/or dealer'],
  businessProhibitedCategory: [
    'Dealers of drug paraphernalia',
    'Marijuana-related business',
    'Sexually oriented businesses',
    'Unlawful internet gambling',
    'Virtual Currency',
  ],

  'SQC-40001-1': [
    'Virtual Currency Investments',
    'Marijuana Related Investments',
  ],
  'SQC-40002-1': ['International Mining/Drilling'],
  'SQC-40007-1': [
    'Third-party Payment Processors/Third-party Service Providers/Third-party Senders (TPPP/TPSP/TPS)',
  ],
  'QC-1020': ['Yes'],
  'QC-1021': ['Yes'],

  'QC-1048': [
    'Casinos and card clubs.',
    'Money services businesses (MSB)',
    'Payday Lending',
    'Peer to Peer (P2P) Lending',
    'Operators of credit card systems',
    'Dealers in Precious metals, stones, jewels and/or Pawnbrokers',
    'Art/Antique Dealers',
  ],
};
