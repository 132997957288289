import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { OktaAuth } from "@okta/okta-auth-js";
import { AuthService } from "../services/auth.service";
import { OKTA_AUTH } from '@okta/okta-angular';
import { AppFacade } from '@app/core';
import { environment } from "@env/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth, private authService: AuthService, private appFacade: AppFacade) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (environment.oktaLocal) {
      if (await this.authService.isLoggedIn()) {
        this.appFacade.getUserInfo();
        return true;
      } else {
        try {
          await this.oktaAuth.signInWithRedirect({ originalUri: state.url });
          return false;
        } catch (err) {
          console.error(err);
          return false;
        }
      }
    } else {
      return true;
    }
  }
}