import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { AppFacade, linkToGlobalState, PreApplicationService, SampleServiceService } from "@app/core";
import { Observable } from "rxjs/internal/Observable";
import { Store } from '@ngrx/store';
import { QuestionnaireService } from "@app/core/services/questionnaire.service";
import { ApplicationFlow, highRiskIndustriesValues } from '@app/models/application-flow';
import { DocumentUploadService } from "@app/core/services/document-upload.service";
import { DashBoardService } from "@app/core/services/dashboard.service";
import { ToastrService } from "@app/core/services/toastr.service";
import { getApartmentNumberIdentifierKey } from "@app/models/apartmentnumberIdentifiers";
import { combineLatest } from "rxjs";
import {EstateService} from "@app/core/services/estate.service";
import { TrustService } from "@app/core/services/trust.service";

export interface Questions {
    businessProfileQuestions: any;
    geographyQuestions: any;
    regulatoryQuestions: any;
    securitiesQuestions: any;
    mbsTbaQuestions: any;
    additionalBusinessQuestions: any;
    estateRelatedQuestions: any;
    trustRelatedQuestions: any;
    isDataLoaded: boolean;
    dataError: any;
}

export interface Answers {
    businessProfileAnswers: any;
    geographyAnswers: any;
    regulatoryAnswers: any;
    securitiesAnswers: any;
    mbsTbaAnswers: any;
    additionalBusinessAnswers: any;
    estateRelatedAnswers: any;
    trustRelatedAnswers: any;
    isDataLoaded: boolean;
    dataError: any;
}

export interface ProfileInfo {
    profileInfo: any;
    isDataLoaded: boolean;
    dataError: any;
}

export interface AdditonalProfiles {
    coapplicants: any,
    controllingParty: any,
    ownershipInterest: any,
    authorizedSigner: any,
    authorizedTrader: any,
    primaryApplicant: any,
    trustOwnershipInterest: any
}

export interface UltimatePageModel {
    applicationId: any,
    organizationId: any,
    beneficialOwnershipType: any,
    beneficialOwnershipTypeId: any,
    beneficiaryOwnershipFlag: any,
    taxExemptCode: any,
    taxExempted: any,
    uboChanged: any
}


export interface Products {
    products: any;
    isDataLoaded: boolean;
    dataError: any;
}

export interface Navigation {
    navigationData: any[];
}

export interface ApplicationPageState {
    questions: Questions,
    answers: Answers,
    profileInfo: ProfileInfo,
    uploadedDocuments: any[],
    ultimate: UltimatePageModel,
    additionalProfiles: AdditonalProfiles,
    loadAccountQuestions: LoadAccountQuestions,
    accountAnswer: AccountAnswer,
    fullAccountAnswers: any,
    estateData: any,
    trustData: any,
    products: Products,
    navigation: Navigation
}


export interface UltimatePageState {
    ultimate: UltimatePageModel
}
export interface LoadAccountQuestions {
    accountQuestions: any
}
export interface AccountAnswer {
    accountAnswer: any;
}

export interface FullAccountAnswers {
  fullAccountAnswers: any;
}

@Injectable()
export class ApplicationStore extends ComponentStore<ApplicationPageState> {
    applicationID: any = window.location.pathname.split('/')[1];
    userInfo: any = {};
    constructor(
      private ss: SampleServiceService,
      private globalStore: Store,
      private questionnaireService: QuestionnaireService,
      private preApplicationService: PreApplicationService,
      private documentUploadSerivce: DocumentUploadService,
      private dashboardService: DashBoardService,
      private appFacade: AppFacade,
      private toastrService: ToastrService,
      private estateService: EstateService,
      private trustService: TrustService
    ) {
        super({
            questions: {
                businessProfileQuestions: [],
                geographyQuestions: [],
                regulatoryQuestions: [],
                securitiesQuestions:[],
                mbsTbaQuestions: [],
                additionalBusinessQuestions: [],
                estateRelatedQuestions: [],
                trustRelatedQuestions: [],
                isDataLoaded: false,
                dataError: null
            },
            answers: {
                businessProfileAnswers: [],
                geographyAnswers: [],
                regulatoryAnswers: [],
                securitiesAnswers:[],
                mbsTbaAnswers: [],
                additionalBusinessAnswers: [],
                estateRelatedAnswers: [],
                trustRelatedAnswers:[],
                isDataLoaded: false,
                dataError: null
            },
            profileInfo: {
                profileInfo: {},
                isDataLoaded: false,
                dataError: null,
            },
            uploadedDocuments: [],
            ultimate: {
                applicationId: "",
                organizationId: '',
                beneficialOwnershipType: "",
                beneficialOwnershipTypeId: "",
                beneficiaryOwnershipFlag: "",
                taxExemptCode: "",
                taxExempted: "",
                uboChanged: ""
            },
            additionalProfiles: {
                coapplicants: [],
                controllingParty: [],
                ownershipInterest: [],
                authorizedSigner: [],
                primaryApplicant: [],
                authorizedTrader: [],
                trustOwnershipInterest: []
            },
            loadAccountQuestions: {
                accountQuestions: []
            },
            accountAnswer: {
                accountAnswer: []
            },
          fullAccountAnswers: {},
          estateData: {},
          trustData: {},
          products: {
                products: {},
                isDataLoaded: false,
                dataError: null,
            },
            navigation: {
                navigationData: []
            }
        })
        linkToGlobalState(this.state$, 'ApplicationStore', this.globalStore);

        this.appFacade.getUserInfo$.subscribe((userInfo: any) => {
            this.userInfo = userInfo;
        });
    }


    getProfile() {
        this.effect(() => {
            return this.appFacade.getBsaProfile$.pipe(
                tapResponse(
                    (data: any) => {
                        var controlPerson = [];
                        var beneficialOwner = [];
                        var authorizedSigner = [];
                        var authorizedTrader = [];
                        var primaryApplicant = [];
                        var trustOwner = [];
                        var coApplicants = [];
                        data.coApplicants.forEach((additionalProfiles) => {
                            var date = new Date();
                            additionalProfiles = { ...additionalProfiles, modifiedDate: (new Date(date.setDate(date.getDate() - 2))) }
                            if (additionalProfiles.controlPerson) {
                                controlPerson.push(additionalProfiles)
                            }
                            if (additionalProfiles.beneficialOwner) {
                                beneficialOwner.push(additionalProfiles)
                            }
                            if (additionalProfiles.authorizedSigner) {
                                authorizedSigner.push(additionalProfiles)
                            }
                            if (additionalProfiles.primaryApplicant) {
                                primaryApplicant.push(additionalProfiles)
                            }
                            if(additionalProfiles.authorizedTrader){
                                authorizedTrader.push(additionalProfiles) 
                            }
                            if(additionalProfiles.trust){
                                trustOwner.push(additionalProfiles) 
                            }
                            coApplicants.push(additionalProfiles)
                        })
                        this.patchState(() => ({
                            additionalProfiles: {
                                controllingParty: controlPerson,
                                ownershipInterest: beneficialOwner,
                                authorizedSigner: authorizedSigner,
                                authorizedTrader: authorizedTrader,
                                primaryApplicant: primaryApplicant,
                                trustOwnershipInterest: trustOwner,
                                coapplicants: coApplicants
                            }
                        }))
                        this.patchState(() => ({
                            profileInfo: {
                                profileInfo: data,
                                isDataLoaded: true,
                                dataError: false,
                            }
                        }))
                    },
                    (error: any) => {
                        this.patchState(() => ({
                            profileInfo: {
                                profileInfo: null,
                                isDataLoaded: false,
                                dataError: error,
                            }
                        }))
                    }
                )
            )
        })
    }

    postProfile(data, currentStep, currentIndex, appId) {
        this.effect(() => {
            return this.preApplicationService.postAdditionalProfile(data, appId).pipe(
                tapResponse(
                    (data: any) => {
                        this.patchState(() => ({
                            profileInfo: {
                                profileInfo: data.application,
                                isDataLoaded: true,
                                dataError: false,
                            }
                        }))
                        this.appFacade.updateBsaProfile(data.application);
                        this.putApplicationStep(currentStep, currentIndex, false);
                    },
                    (error: any) => {
                        this.patchState(() => ({
                            profileInfo: {
                                profileInfo: data.application,
                                isDataLoaded: false,
                                dataError: error,
                            }
                        }))
                    }
                )
            )
        })
    }

    putApplicationStep(currentStep, currentIndex, isProfile: boolean) {
        this.putApplicationData(this.contructNavigationPayload(currentStep, currentIndex, isProfile));
    }

    putApplicationData(payload: any) {
        this.effect(() => {
            return this.preApplicationService.updateNavigation(payload).pipe(
                tapResponse((data: any) => {
                    this.getNavData();
                },
                (error: any) => {
                    this.patchState(() => ({
                        navigation: {
                            navigationData: []
                        }
                    }))
                })
            )
        })
    }

    getNavData() {
        this.effect(() => {
            return this.preApplicationService.getNavigation(this.applicationID).pipe(
                tapResponse((response) => {
                    this.patchState(() => ({
                        navigation: {
                            navigationData: response
                        }
                    }))
                }, 
                (error: any) => {
                    this.patchState(() => ({
                        navigation: {
                            navigationData: []
                        }
                    }))
                })
            )
        })
    }

    contructNavigationPayload(currentStep, currentIndex, isProfile: boolean): any {
        return {
            applicationId: this.applicationID,
            applicationStep: `/onboard/${isProfile ? 'applicant' : 'application'}/${currentStep}/${this.applicationID}`,
            updatedAt: new Date(),
            updatedBy: this.userInfo?.name ?? '',
            applicationType: 'External'
        };
    }


    changeStatus(status, application, comments, step, currentStep, currentIndex, pageStage) {
        this.effect(() => {
            return this.dashboardService.updateApplicationStatus(status, application, comments, step, pageStage).pipe(
                tapResponse((data: any) => {
                    let updatedApp = {
                        ...application,
                        applicationStatus: 'PENDING PRELIMINARY CHECKS',
                    };

                    this.patchState(() => ({
                        profileInfo: {
                            profileInfo: updatedApp,
                            isDataLoaded: true,
                            dataError: false,
                        }
                    }))
                    //this.putApplicationStep(currentStep, currentIndex);
                    //this.appFacade.updateBsaProfile(updatedApp);
                },
                    (error: any) => {
                        this.patchState(() => ({
                            profileInfo: {
                                profileInfo: application,
                                isDataLoaded: false,
                                dataError: error,
                            }
                        }))
                    }
                )
            )
        })
    }

    getUploadedDocuments(applicationId: number, source: string, layerEntityName: string) {
        this.effect(() => {
            return this.documentUploadSerivce.getUploadedDocuments(applicationId, source, layerEntityName).pipe(
                tapResponse(
                    (data: any) => {
                        this.patchState(() => ({
                            uploadedDocuments: data
                        }));
                    },
                    (error: any) => {
                        this.patchState(() => ({
                            uploadedDocuments: []
                        }))
                    }
                )
            )
        })
    }

    uploadDocument(data, appID, source: string, layerEntityName: string) {
        this.effect(() => this.documentUploadSerivce.uploadDocument(data, appID).pipe(tapResponse(
            (data: any) => {
                this.getUploadedDocuments(appID, source, layerEntityName);
            },
            (error: any) => {
                console.log('Error Uploading Document', error);
            }
        )))
    }


    getQuestions(appId) {
        this.effect(() => {
            return this.questionnaireService.getQuestions(appId).pipe(
                tapResponse(
                    (questions: any) => {
                        var businessProfileQuestions = [];
                        var geographyQuestions = [];
                        var regulatoryQuestions = [];
                        var securitiesQuestions= [];
                        var mbsTbaQuestions = [];
                        var additionalBusinessQuestions = [];
                        var ultimateBeneficalOwnershipQuestions = [];
                        var estateRelatedQuestions = [];
                        var trustRelatedQuestions = [];

                        questions.filter((question: any) => {
                            if (question.profileCategory === 'BUSINESS PROFILE') { businessProfileQuestions.push(question) }
                            if (question.profileCategory === 'GEOGRAPHY') { geographyQuestions.push(question) }
                            if (question.profileCategory === 'REGULATORY AND LEGAL') { regulatoryQuestions.push(question) }
                            if (question.profileCategory === 'Securities') { securitiesQuestions.push(question) }
                            if (question.profileCategory === 'MBS-TBA') { mbsTbaQuestions.push(question) }
                            if (question.profileCategory === 'ADDITIONAL BUSINESS QUESTIONS') { additionalBusinessQuestions.push(question) }
                            if (question.profileCategory === 'ULIMATE BENEFICIAL OWNERSHIP') { ultimateBeneficalOwnershipQuestions.push(question) }
                            if (question.profileCategory === 'ESTATE RELATED QUESTIONS') { estateRelatedQuestions.push(question) }
                            if (question.profileCategory === 'TRUST RELATED QUESTIONS') { trustRelatedQuestions.push(question) }

                        })

                        this.patchState(() => ({
                            questions: {
                                businessProfileQuestions: businessProfileQuestions,
                                geographyQuestions: geographyQuestions,
                                regulatoryQuestions: regulatoryQuestions,
                                securitiesQuestions: securitiesQuestions,
                                mbsTbaQuestions: mbsTbaQuestions,
                                additionalBusinessQuestions: additionalBusinessQuestions,
                                ultimateBeneficalOwnershipQuestions: ultimateBeneficalOwnershipQuestions,
                                estateRelatedQuestions: estateRelatedQuestions,
                                trustRelatedQuestions: trustRelatedQuestions,
                                isDataLoaded: true,
                                dataError: false,
                            }
                        }))
                        
                    },
                    (error: any) => {
                        this.patchState(() => ({
                            questions: {
                                businessProfileQuestions: null,
                                geographyQuestions: null,
                                regulatoryQuestions: null,
                                securitiesQuestions:null,
                                mbsTbaQuestions: null,
                                additionalBusinessQuestions: null,
                                ultimateBeneficalOwnershipQuestions: null,
                                estateRelatedQuestions: null,
                                trustRelatedQuestions: null,
                                isDataLoaded: false,
                                dataError: error
                            }
                        }))
                    }
                )
            )
        })
    }

    getAccountQuestions(appId) {
        this.effect(() => {
            return this.questionnaireService.loadAccountQuestions(appId).pipe(
                tapResponse(
                    (data: any) => {
                        this.patchState(() => ({
                            loadAccountQuestions: {
                                accountQuestions: data,
                            }

                        }))
                    },
                    (error: any) => {
                        this.patchState(() => ({
                            loadAccountQuestions: {
                                accountQuestions: null,
                            }
                        }))
                    }
                )
            )
        })
    }

    getAccountAnswer(appID) {
        this.effect(() => {
            return this.questionnaireService.getAccountAnswers(appID).pipe(
                tapResponse(
                    (data: any) => {
                        var final = [];
                        data.forEach((ele) => {
                            final.push(JSON.parse(ele.accountActivity))
                        })
                        this.patchState(() => ({
                            accountAnswer: {
                                accountAnswer: final,
                            },
                            fullAccountAnswers: data
                        }))
                    },
                    (error: any) => {
                        this.patchState(() => ({
                            accountAnswer: {
                                accountAnswer: null,
                            },
                            fullAccountAnswers: null
                        }))
                    }
                )
            )
        })
    }

    getAnswers(appID) {
        this.effect(() => {
            return this.questionnaireService.getAnswers(appID).pipe(
                tapResponse(
                    (answers: any) => {
                        this.patchState(() => ({
                            answers: {
                                businessProfileAnswers: JSON.parse(answers?.businessProfile),
                                geographyAnswers: JSON.parse(answers?.geography),
                                regulatoryAnswers: JSON.parse(answers?.regulatoryLegal),
                                securitiesAnswers: JSON.parse(answers?.securityQuestions),
                                mbsTbaAnswers: JSON.parse(answers?.mbsTbaQuestions),
                                additionalBusinessAnswers: JSON.parse(answers?.additionalBusinessQuestions),
                                estateRelatedAnswers: [],
                                trustRelatedAnswers: [],
                                isDataLoaded: true,
                                dataError: false,
                            }
                        }))
                    },
                    (error: any) => {
                        this.patchState(() => ({
                            answers: {
                                businessProfileAnswers: null,
                                geographyAnswers: null,
                                regulatoryAnswers: null,
                                securitiesAnswers: null,
                                mbsTbaAnswers: null,
                                additionalBusinessAnswers: null,
                                estateRelatedAnswers: null,
                                trustRelatedAnswers: null,
                                isDataLoaded: false,
                                dataError: true,
                            }
                        }))
                    }
                )
            )
        })
    }

    postProfileAnswers(view, data, currentStep, currentIndex, appId, exitStep: any) {
        var applicationFlow = ApplicationFlow;
        var payload;
        var final = [];
        var profileData;
        let industryHighRisk = false;
        if (data) {
            industryHighRisk = this.checkIndustryHighRisk(data?.qa);
            (data?.qa).forEach((ele) => {
                let ans = ele.answer;
                ans = ele.questionType === 'ngselect' && Array.isArray(ans) ? JSON.stringify(ans.map(e => e)) : ele.answer !== '' ? `\"${ele.answer}\"` : "null";

                if(ele.questionCode === 'QC-1008' || ele.questionCode === 'QC-1054'){
                  if(ans === 'null' || ans?.replaceAll('"', '') === 'null') {
                    ans = undefined;
                  }
                }

                var data = { questionCode: ele.questionCode, answer: ans, addressContactDTO: ele.addressContactDTO, questionDescription: ele.questionDescription ?? ele.displayQuestion, subQuestionnaireAnswers: this.setSub(ele.subQuestionnaireAnswers), }
                final.push(data)
            });
            final.find((ele) => { if (ele.questionCode === 'QC-1055' || ele.questionCode === 'QC-1056') {
              ele.answer = JSON.stringify(getApartmentNumberIdentifierKey(JSON.parse(ele.answer)));
            } });
            this.profileInformation$.subscribe(res => {
                profileData = res;
                var intPayload = {
                  applicationId: res.applicationId,
                  bsaType: "standard",
                  customerId: res.applicant.customerId,
                  dbaDTOS: [],
                  mainQuestionnaireAnswer: final,
                  dateEstablished: final.find((ele) => { if (ele.questionCode === 'QC-1003') { return ele } })?.answer ?
                    JSON.parse(final.find((ele) => { if (ele.questionCode === 'QC-1003') { return ele } })?.answer) : res.organization.dateEstablished,
                  industryHighRisk: sessionStorage.getItem('isHighRisk')==='true'?true:industryHighRisk,
                  organizationId: res.organization.id,
                  profileCategory: view
                };

                if (view === applicationFlow.businessProfile) {
                    var isDba = final.find((ele) => { if (ele.questionCode === 'QC-1022') { return ele } });
                    if (JSON.parse(isDba.answer) === 'Yes') {
                        var rsCode, taxIdFlag, tin;
                        if (JSON.parse(isDba.subQuestionnaireAnswers.find((ele) => { if (ele.questionCode === 'SQC-10000-2') { return ele } }).answer) === 'Yes') {
                            rsCode = 'TAX'
                            taxIdFlag = JSON.parse(isDba.subQuestionnaireAnswers.find((ele) => { if (ele.questionCode === 'SQC-10002-1') { return ele } })?.answer),
                                tin = JSON.parse(isDba.subQuestionnaireAnswers.find((ele) => { if (ele.questionCode === 'SQC-10001-1') { return ele } })?.answer)
                        } else {
                            rsCode = 'DBA',
                                taxIdFlag = '',
                                tin = ''
                        }
                        payload = {
                            ...intPayload,
                            dbaDTOS: [
                                {
                                    name: JSON.parse(isDba.subQuestionnaireAnswers.find((ele) => { if (ele.questionCode === 'SQC-10000-1') { return ele } }).answer),
                                    relationshipCode: rsCode,
                                    taxIdFlag: taxIdFlag,
                                    tin: tin
                                }
                            ]
                        }
                        return payload
                    }
                    else {
                        payload = {
                            ...intPayload,
                            dbaDTOS: []
                        }
                        return payload
                    }
                } else {
                    payload = intPayload;
                }

                if (view === applicationFlow.geography) {
                    let primaryCountry = final.find((val) => val.questionDescription === 'Primary Country of Registration')?.answer?.replaceAll('"', '') ?? '';
                    let primaryState =
                        primaryCountry === 'United States' ?
                            final.find((val) => val.questionDescription === 'Primary Country of Registration')?.subQuestionnaireAnswers.find((subVal) => subVal.questionCode === 'SQC-20003-1')?.answer?.replaceAll('"', '') ?? '' : '';

                    let codes = this.findCodes(data.qa);
                    payload = {
                        ...intPayload,
                        primaryCountryOfReg: primaryCountry,
                        primaryStateOfReg: primaryState,
                        bsaType: "standard",
                        addressContactDTO: {
                            ...res.organization.address,
                            address1: JSON.parse(final.find((ele) => { if (ele.questionCode === 'QC-1035') { return ele.answer } }).answer),
                            address2: JSON.parse(final.find((ele) => { if (ele.questionCode === 'QC-1036') { return ele.answer  } }).answer) === 'null' ? null : JSON.parse(final.find((ele) => { if (ele.questionCode === 'QC-1036') { return ele.answer  } }).answer),
                            city: JSON.parse(final.find((ele) => { if (ele.questionCode === 'QC-1038') { return ele.answer } }).answer),
                            state: codes.countryAlpha3 !== 'USA' ? undefined : JSON.parse(final.find((ele) => { if (ele.questionCode === 'QC-1037') { return ele.answer } }).answer),
                            stateCode: codes.stateCode,
                            zipCode: codes.countryAlpha3 !== 'USA' ? undefined : JSON.parse(final.find((ele) => { if (ele.questionCode === 'QC-1039') { return ele.answer } }).answer),
                            country: JSON.parse(final.find((ele) => { if (ele.questionCode === 'QC-1040') { return ele.answer } }).answer),
                            countryAlpha3: codes.countryAlpha3,
                            countryAlpha2: codes.countryAlpha2,
                            usAddress: codes.countryAlpha3 === 'USA',
                            postalCode: codes.countryAlpha3 === 'USA' ? undefined : JSON.parse(final.find((ele) => { if (ele.questionCode === 'QC-1052') { return ele.answer  } }).answer),
                            provinces: codes.countryAlpha3 === 'USA' ? undefined : JSON.parse(final.find((ele) => { if (ele.questionCode === 'QC-1042') { return ele.answer  } }).answer),
                            apartmentNumberId: getApartmentNumberIdentifierKey(JSON.parse(final.find((ele) => { if (ele.questionCode === 'QC-1055') { return ele.answer } }).answer))
                        }
                    }
                } else {
                    payload = intPayload;
                }
            })
            this.effect(() => {
                return this.questionnaireService.postAnswers(payload, view, appId).pipe(
                    tapResponse(
                        (answers: any) => {
                            this.appFacade.getBsaProfile();
                            if (view === applicationFlow.businessProfile) { this.addBusinessProfileAnswers(payload.mainQuestionnaireAnswer) }
                            if (view === applicationFlow.geography) { this.addGeographyAnswers(payload.mainQuestionnaireAnswer) }
                            if (view === applicationFlow.regulatoryLegal) { this.addRegulatoryAnswers(payload.mainQuestionnaireAnswer) }
                            if (view === applicationFlow.securities) { this.addSecurities(payload.mainQuestionnaireAnswer) }
                            if (view === applicationFlow.MBS_TBA) { this.addMbsTbaAnswers(payload.mainQuestionnaireAnswer) }
                            if (view === applicationFlow.additionalQuestions) { this.addAdditionalBusinessAnswers(payload.mainQuestionnaireAnswer) };
                            if (view === applicationFlow.estateRelatedQuestions) { this.addEstateRelatedAnswers(payload.mainQuestionnaireAnswer) }
                            if (view === applicationFlow.trustRelatedQuestions) { this.addTrustRelatedAnswers(payload.mainQuestionnaireAnswer) }
                            if (currentStep === exitStep) { window.location.href = "https://www.texascapitalbank.com/commercial-banking" };
                            this.putApplicationStep(currentStep, currentIndex, false);
                            this.toastrService.success('Data Saved succesfully');
                        },
                        (error: any) => {
                            this.toastrService.error('Error!. Please try again');
                            console.log(error);
                        }
                    )
                )
            })
        }
    }

  getEstateData(appId: any) {
    this.effect(() => {
      return this.estateService.getEstateData(appId).pipe(
        tapResponse((estateData: any) => {
            this.patchState(() => ({
                estateData: estateData
            }))
          },
          (error: any) => {
            this.patchState(() => ({
              estateData: null
            }))
          }
        )
      )
    });
  }

  saveEstateData(payload: any) {
    this.effect(() => {
      return this.estateService.saveEstateRelatedQsn(payload).pipe(
        tapResponse((estateData: any) => {
            this.patchState(() => ({
              estateData: estateData
            }))
          },
          (error: any) => {
            this.patchState(() => ({
              estateData: null
            }))
          }
        )
      )
    });
  }

  getTrustData(appId: any) {
    this.effect(() => {
      return this.trustService.getTrustData(appId).pipe(
        tapResponse((trustData: any) => {
            this.patchState(() => ({
                trustData: trustData
            }))
          },
          (error: any) => {
            this.patchState(() => ({
              trustData: null
            }))
          }
        )
      )
    });
  }

  saveTrustData(payload: any) {
    this.effect(() => {
      return this.trustService.saveTrustRelatedQsn(payload).pipe(
        tapResponse((trustData: any) => {
            this.patchState(() => ({
              trustData: trustData
            }))
          },
          (error: any) => {
            this.patchState(() => ({
              trustData: null
            }))
          }
        )
      )
    });
  }

  findCodes(questions: any[]) {
      let primaryCountryQuestion = questions.find((question) => question.questionCode === 'QC-1040');
      let country = primaryCountryQuestion.answer;
      let value = primaryCountryQuestion.possibleValues.find((posibleValue) => posibleValue.itemName === country || posibleValue.name === country);
      let stateCode = null;
      if(value.countryAlpha3 === 'USA') {
        let stateQuestion = questions.find((question) => question.questionCode === 'QC-1037');
        let answer = stateQuestion.answer;
        let stateValue = stateQuestion.possibleValues.find((posibleValue) => posibleValue.name === answer);
        stateCode = stateValue.abbreviation;
      }
      return {countryAlpha3: value.countryAlpha3 ?? '', countryAlpha2: value.countryAlpha2 ?? '', stateCode: stateCode};
    }

    setSub(data) {
        const final = [];
        data.forEach(ele => {
            if (ele.answer) {
                let ans = ele.answer;

                ans = ele.questionType === 'ngselect' && Array.isArray(ans) ? JSON.stringify(ans.map(e => e)) : `\"${ele.answer}\"`;
                const req = {
                    questionCode: ele.questionCode,
                    answer: ans,
                    addressContactDTO: ele.addressContactDTO,
                    questionDescription: ele.questionDescription ?? ele.displayQuestion,
                };
                final.push(req);
            }
        });
        return final;
    }

    checkIndustryHighRisk(question: any) {
        let highRisk = false;
        question.forEach((ele: any) => {
            let ans = ele.answer;

            if (highRiskIndustriesValues[ele?.questionCode]) {

                if (Array.isArray(ans) && ans.some((x: any) => highRiskIndustriesValues[ele?.questionCode]?.includes(x))) {
                    return highRisk = true;


                } else if (highRiskIndustriesValues[ele?.questionCode]?.includes(ans)) {


                    return highRisk = true;
                }
            } else if (ele?.subQuestionnaireAnswers) {
                ele?.subQuestionnaireAnswers.forEach((each: any) => {
                    let subAns = each?.answer;

                    if (subAns) {
                        if (Array.isArray(subAns) && subAns.some((x: any) => highRiskIndustriesValues[each?.questionCode]?.includes(x))) {
                            return highRisk = true;

                        } else if (highRiskIndustriesValues[each?.questionCode]?.includes(subAns)) {

                            return highRisk = true;
                        }
                    }
                });
            }
        });
        return highRisk;
    }

    addBusinessProfileAnswers(qa) {
        this.patchState((state: ApplicationPageState) => {
            return {
                answers: {
                    businessProfileAnswers: qa,
                    geographyAnswers: state.answers.geographyAnswers,
                    regulatoryAnswers: state.answers.regulatoryAnswers,
                    securitiesAnswers: state.answers.securitiesAnswers,
                    mbsTbaAnswers: state.answers.mbsTbaAnswers,
                    additionalBusinessAnswers: state.answers.additionalBusinessAnswers,
                    estateRelatedAnswers: [],
                    trustRelatedAnswers: [],
                    isDataLoaded: state.answers.isDataLoaded,
                    dataError: state.answers.dataError
                },
            }
        })
    }

    addGeographyAnswers(qa) {
        this.patchState((state: ApplicationPageState) => {
            return {
                answers: {
                    businessProfileAnswers: state.answers.businessProfileAnswers,
                    geographyAnswers: qa,
                    regulatoryAnswers: state.answers.regulatoryAnswers,
                    securitiesAnswers: state.answers.securitiesAnswers,
                    mbsTbaAnswers: state.answers.mbsTbaAnswers,
                    additionalBusinessAnswers: state.answers.additionalBusinessAnswers,
                    estateRelatedAnswers: [],
                    trustRelatedAnswers: [],
                    isDataLoaded: state.answers.isDataLoaded,
                    dataError: state.answers.dataError
                },
            }
        })
    }


    addRegulatoryAnswers(qa) {
        this.patchState((state: ApplicationPageState) => {
            return {
                answers: {
                    businessProfileAnswers: state.answers.businessProfileAnswers,
                    geographyAnswers: state.answers.geographyAnswers,
                    regulatoryAnswers: qa,
                    securitiesAnswers:state.answers.securitiesAnswers,
                    mbsTbaAnswers: state.answers.mbsTbaAnswers,
                    additionalBusinessAnswers: state.answers.additionalBusinessAnswers,
                    estateRelatedAnswers: [],
                    trustRelatedAnswers: [],
                    isDataLoaded: state.answers.isDataLoaded,
                    dataError: state.answers.dataError
                },
            }
        })
    }

    addMbsTbaAnswers(qa) {
        this.patchState((state: ApplicationPageState) => {
            return {
                answers: {
                    businessProfileAnswers: state.answers.businessProfileAnswers,
                    geographyAnswers: state.answers.geographyAnswers,
                    regulatoryAnswers: state.answers.regulatoryAnswers,
                    securitiesAnswers: state.answers.securitiesAnswers,
                    mbsTbaAnswers: qa,
                    additionalBusinessAnswers: state.answers.additionalBusinessAnswers,
                    estateRelatedAnswers: [],
                    trustRelatedAnswers: [],
                    isDataLoaded: state.answers.isDataLoaded,
                    dataError: state.answers.dataError
                },
            }
        })
    }

    addSecurities(qa) {
        this.patchState((state: ApplicationPageState) => {
            return {
                answers: {
                    businessProfileAnswers: state.answers.businessProfileAnswers,
                    geographyAnswers: state.answers.geographyAnswers,
                    regulatoryAnswers: state.answers.regulatoryAnswers,
                    securitiesAnswers: qa,
                    mbsTbaAnswers: state.answers.mbsTbaAnswers,
                    additionalBusinessAnswers: state.answers.additionalBusinessAnswers,
                    estateRelatedAnswers: [],
                    trustRelatedAnswers: [],
                    isDataLoaded: state.answers.isDataLoaded,
                    dataError: state.answers.dataError
                },
            }
        })
    }


    addAdditionalBusinessAnswers(qa) {
        this.patchState((state: ApplicationPageState) => {
            return {
                answers: {
                    businessProfileAnswers: state.answers.businessProfileAnswers,
                    geographyAnswers: state.answers.geographyAnswers,
                    regulatoryAnswers: state.answers.regulatoryAnswers,
                    securitiesAnswers: state.answers.securitiesAnswers,
                    mbsTbaAnswers: state.answers.mbsTbaAnswers,
                    additionalBusinessAnswers: qa,
                    estateRelatedAnswers: [],
                    trustRelatedAnswers: [],
                    isDataLoaded: state.answers.isDataLoaded,
                    dataError: state.answers.dataError
                },
            }
        })
    }

    addEstateRelatedAnswers(qa) {
      this.patchState((state: ApplicationPageState) => {
        return {
          answers: {
            businessProfileAnswers: state.answers.businessProfileAnswers,
            geographyAnswers: state.answers.geographyAnswers,
            regulatoryAnswers: state.answers.regulatoryAnswers,
            securitiesAnswers: state.answers.securitiesAnswers,
            mbsTbaAnswers: state.answers.mbsTbaAnswers,
            additionalBusinessAnswers: state.answers.additionalBusinessAnswers,
            estateRelatedAnswers: [],
            trustRelatedAnswers: [],
            isDataLoaded: state.answers.isDataLoaded,
            dataError: state.answers.dataError
          },
        }
      });
    }

    addTrustRelatedAnswers(qa) {
        this.patchState((state: ApplicationPageState) => {
          return {
            answers: {
              businessProfileAnswers: state.answers.businessProfileAnswers,
              geographyAnswers: state.answers.geographyAnswers,
              regulatoryAnswers: state.answers.regulatoryAnswers,
              securitiesAnswers: state.answers.securitiesAnswers,
              mbsTbaAnswers: state.answers.mbsTbaAnswers,
              additionalBusinessAnswers: state.answers.additionalBusinessAnswers,
              estateRelatedAnswers: [],
              trustRelatedAnswers: [],
              isDataLoaded: state.answers.isDataLoaded,
              dataError: state.answers.dataError
            },
          }
        });
      }

    addControllingParty(data) {
        this.patchState((state: ApplicationPageState) => {
            return {
                additionalProfiles: {
                    controllingParty: data?.filter((controlParty) => controlParty.controlPerson === true) ?? [],
                    ownershipInterest: state.additionalProfiles.ownershipInterest,
                    authorizedSigner: state.additionalProfiles.authorizedSigner,
                    authorizedTrader: state.additionalProfiles.authorizedTrader,
                    primaryApplicant: state.additionalProfiles.primaryApplicant,
                    trustOwnershipInterest: state.additionalProfiles.trustOwnershipInterest,
                    coapplicants: this.removeDuplicates([].concat(data, state.additionalProfiles.ownershipInterest, state.additionalProfiles.trustOwnershipInterest, state.additionalProfiles.authorizedSigner, state.additionalProfiles.authorizedTrader, state.additionalProfiles.primaryApplicant))
                }
            }
        })
    }

    addTrustOwnershipInterest(data) {
        this.patchState((state: ApplicationPageState) => {
            return {
                additionalProfiles: {
                    controllingParty: state.additionalProfiles.controllingParty,
                    ownershipInterest: state.additionalProfiles.ownershipInterest,
                    authorizedSigner: state.additionalProfiles.authorizedSigner,
                    authorizedTrader: state.additionalProfiles.authorizedTrader,
                    primaryApplicant: state.additionalProfiles.primaryApplicant,
                    trustOwnershipInterest: data?.filter((trust) => trust.trust === true) ?? [],
                    coapplicants: this.removeDuplicates([].concat(state.additionalProfiles.controllingParty, state.additionalProfiles.ownershipInterest, data, state.additionalProfiles.authorizedSigner, state.additionalProfiles.authorizedTrader, state.additionalProfiles.primaryApplicant))
                }
            }
        })
    }

    addOwnershipInterest(data) {
        this.patchState((state: ApplicationPageState) => {
            return {
                additionalProfiles: {
                    controllingParty: state.additionalProfiles.controllingParty,
                    ownershipInterest: data?.filter((beneficialOwner) => beneficialOwner.beneficialOwner === true) ?? [],
                    authorizedSigner: state.additionalProfiles.authorizedSigner,
                    authorizedTrader: state.additionalProfiles.authorizedTrader,
                    primaryApplicant: state.additionalProfiles.primaryApplicant,
                    trustOwnershipInterest: state.additionalProfiles.trustOwnershipInterest,
                    coapplicants: this.removeDuplicates(([].concat(state.additionalProfiles.controllingParty, data, state.additionalProfiles.trustOwnershipInterest, state.additionalProfiles.authorizedSigner, state.additionalProfiles.authorizedTrader, state.additionalProfiles.primaryApplicant)))
                }
            }
        })
    }


    addAuthorizedSigner(data) {
        this.patchState((state: ApplicationPageState) => {
            return {
                additionalProfiles: {
                    controllingParty: state.additionalProfiles.controllingParty,
                    ownershipInterest: state.additionalProfiles.ownershipInterest,
                    authorizedSigner: data?.filter((authSigner) => authSigner.authorizedSigner === true) ?? [],
                    authorizedTrader: state.additionalProfiles.authorizedTrader,
                    primaryApplicant: state.additionalProfiles.primaryApplicant,
                    trustOwnershipInterest: state.additionalProfiles.trustOwnershipInterest,
                    coapplicants: this.removeDuplicates([].concat(state.additionalProfiles.controllingParty, state.additionalProfiles.ownershipInterest, state.additionalProfiles.trustOwnershipInterest, data, state.additionalProfiles.authorizedTrader, state.additionalProfiles.primaryApplicant))
                }
            }
        })
    }

    addAuthorizedTrader(data) {
        this.patchState((state: ApplicationPageState) => {
            return {
                additionalProfiles: {
                    controllingParty: state.additionalProfiles.controllingParty,
                    ownershipInterest: state.additionalProfiles.ownershipInterest,
                    authorizedSigner: state.additionalProfiles.authorizedSigner,
                    authorizedTrader: data?.filter((authTrader) => authTrader.authorizedTrader === true) ?? [],
                    primaryApplicant: state.additionalProfiles.primaryApplicant,
                    trustOwnershipInterest: state.additionalProfiles.trustOwnershipInterest,
                    coapplicants: this.removeDuplicates([].concat(state.additionalProfiles.controllingParty, state.additionalProfiles.ownershipInterest, state.additionalProfiles.trustOwnershipInterest, state.additionalProfiles.authorizedSigner, data, state.additionalProfiles.primaryApplicant))
                }
            }
        })
    }

    saveAdditionaProfiles(currentStep, currentIndex, appId: any) {
        var additonalProfiles = [];
        this.patchState((state: ApplicationPageState) => {
            var profile = state.profileInfo.profileInfo;
            var payload = {
                application: {
                    ...profile,
                    coApplicants: this.removeCustomerId(this.removeDuplicates(state.additionalProfiles.coapplicants))
                }
            }
            this.postProfile(payload, currentStep, currentIndex, appId);
            return {}
        })
    }

    removeCustomerId(arr) {
        var finalArray = [];
        this.getUnique(arr).forEach((ele) => {
            var objVal = {
                ...ele,
                customerId: (ele.customerId) ? (ele.customerId?.includes("NEW")) ? null : ele.customerId : null
            }
            finalArray.push(objVal);
        })
        return finalArray;
    }



    removeDuplicates(arr) {
        if (arr.length === 1) {
            return arr
        } else {
            var newArray = arr.filter((arrayValue) => arrayValue.modifiedDate)
            arr = (newArray.sort((a, b) => new Date(b.modifiedDate).getTime() - new Date(a.modifiedDate).getTime()));
            return this.getUnique(arr);
        }
    }


    getUnique(arr) {
        var result = arr.reduce((unique, o) => {
            if (!unique.some(obj => obj.customerId === o.customerId)) { unique.push(o) }
            return unique;
        }, []);
        return result;
    }


    cleanState() {
        this.setState((state: ApplicationPageState) => {
            return {
                questions: {
                    businessProfileQuestions: [],
                    geographyQuestions: [],
                    regulatoryQuestions: [],
                    securitiesQuestions: [],
                    mbsTbaQuestions: [],
                    additionalBusinessQuestions: [],
                    ultimateBeneficalOwnershipQuestions: [],
                    estateRelatedQuestions: [],
                    trustRelatedQuestions: [],
                    isDataLoaded: true,
                    dataError: false
                },
                answers: {
                    businessProfileAnswers: [],
                    geographyAnswers: [],
                    regulatoryAnswers: [],
                    securitiesAnswers: [],
                    mbsTbaAnswers: [],
                    additionalBusinessAnswers: [],
                    ultimateBeneficalOwnershipAnswers: [],
                    estateRelatedAnswers: [],
                    trustRelatedAnswers: [],
                    isDataLoaded: false,
                    dataError: null
                },
                profileInfo: {
                    profileInfo: {},
                    isDataLoaded: false,
                    dataError: null,
                },
                uploadedDocuments: [],
                ultimate: {
                    applicationId: "",
                    organizationId: '',
                    beneficialOwnershipType: "",
                    beneficialOwnershipTypeId: "",
                    beneficiaryOwnershipFlag: "",
                    taxExemptCode: "",
                    taxExempted: "",
                    uboChanged: ""
                },
                additionalProfiles: {
                    coapplicants: [],
                    controllingParty: [],
                    ownershipInterest: [],
                    authorizedSigner: [],
                    authorizedTrader: [],
                    primaryApplicant: [],
                    trustOwnershipInterest: [],
                },
                loadAccountQuestions: {
                    accountQuestions: []
                },
                accountAnswer: {
                    accountAnswer: []
                },
                fullAccountAnswers: {},
                estateData: {},
                trustData: {},
                products: {
                    products: {},
                    isDataLoaded: false,
                    dataError: null,
                },
                navigation: {
                    navigationData: []
                }
            }
        })
    }

    postUltimateData(ultimateValues, currentStep, currentIndex, appId, exitStep) {
        this.effect(() => {
            return this.preApplicationService.postUltimateData(ultimateValues, appId).pipe(
                tapResponse((bsaresponse: any) => {
                    //this.putApplicationStep(currentStep,currentIndex);
                    if (currentStep === exitStep) { window.location.href = "https://www.texascapitalbank.com/commercial-banking" }
                    this.toastrService.success('Data Saved succesfully');
                },
                    (error: any) => {
                        console.log(error)
                        this.toastrService.error('Error!. Please try again');
                    }
                )
            )
        })

    }

    calculateAddress(question: any, id: any): any {
      let address: any;
      let subQestionAnswers = question.subQuestionnaireAnswers;
      if(question.answer.replaceAll('"', '') === 'No' && subQestionAnswers?.length) {
        let country = subQestionAnswers.find((subQ) => subQ.questionCode === 'SQC-100040-8').answer.replaceAll('"', '');

        if(country === 'United States') {
          address = {
            id: id ?? undefined,
            address1: subQestionAnswers.find((subQ) => subQ.questionCode === 'SQC-100040-1').answer.replaceAll('"', ''),
            address2: subQestionAnswers.find((subQ) => subQ.questionCode === 'SQC-100040-2')?.answer.replaceAll('"', '') ?? null,
            city: subQestionAnswers.find((subQ) => subQ.questionCode === 'SQC-100040-3').answer.replaceAll('"', ''),
            state: subQestionAnswers.find((subQ) => subQ.questionCode === 'SQC-100040-5').answer.replaceAll('"', ''),
            zipCode: subQestionAnswers.find((subQ) => subQ.questionCode === 'SQC-100040-6').answer.replaceAll('"', ''),
            country: country,
            usAddress: true,
          };
        } else {
          address = {
            id: id ?? undefined,
            address1: subQestionAnswers.find((subQ) => subQ.questionCode === 'SQC-100040-1').answer.replaceAll('"', ''),
            address2: subQestionAnswers.find((subQ) => subQ.questionCode === 'SQC-100040-2')?.answer.replaceAll('"', '') ?? null,
            city: subQestionAnswers.find((subQ) => subQ.questionCode === 'SQC-100040-3').answer.replaceAll('"', ''),
            postalCode: subQestionAnswers.find((subQ) => subQ.questionCode === 'SQC-100040-7').answer.replaceAll('"', ''),
            provinces:  subQestionAnswers.find((subQ) => subQ.questionCode === 'SQC-100040-4').answer.replaceAll('"', ''),
            country: country,
            usAddress: false,
          };
        }
      } else {
        address = undefined;
      }

      return address
    }

  postAccountQuestion(url, data, account, currentStep, currentIndex, appId) {
        var payload;
        if (data) {
          combineLatest([this.profileInformation$, this.fullAccountAnswers$]).subscribe(([profileInformation, fullAccountAnswers]) => {
            let currentAddress = fullAccountAnswers.find((acountAnswers: any) => acountAnswers.accountNumber === account.accountNumber);
            let address = this.calculateAddress(data.find((question) => question.questionCode === 'QC-10027'), currentAddress?.addressContactInfo?.id);
            var intPayload = {
              accountId: profileInformation.applicant.customerId,
              accountName: account.product.productDesc,
              address: address,
              accountNumber: account.accountNumber,
              applicationId: profileInformation.applicationId,
              bsaType: "standard",
              customerId: profileInformation.applicant.customerId,
              mainQuestionnaireAnswer: data,
              profileCategory: 'account'
            }
            payload = intPayload;
          });
            this.effect(() => {
                return this.questionnaireService.postAccountAnswers(url, payload, appId).pipe(
                    tapResponse(
                        (answers: any) => {
                            this.putApplicationStep(currentStep, currentIndex, false);
                            this.getAccountAnswer(appId);
                        },
                        (error: any) => {
                            console.log(error);
                        }
                    )
                )
            })
        }
    }

    addAccountActivity(ele, i, product, currentStep, currentIndex, appId) {
        this.patchState((state: AccountAnswer) => {
            var answer = { ...state.accountAnswer.accountAnswer };
            var final = [];
            (ele?.qa).forEach((ele) => {
                let ans = ele.answer;
                var data = { 
                    questionCode: ele.questionCode, 
                    answer:  ele.questionType === 'ngselect' && Array.isArray(ans) ? JSON.stringify(ans.map(e => e)) : ele.answer !== '' ? `\"${ele.answer}\"` : "null", 
                    addressContactDTO: ele.addressContactDTO, 
                    questionDescription: ele.questionDescription ?? ele.displayQuestion, 
                    subQuestionnaireAnswers: this.setSub(ele.subQuestionnaireAnswers) 
                }
                final.push(data)
            })
            final.find((element) => { if (element.questionCode === 'QC-10027') {
              if (element.subQuestionnaireAnswers && element.subQuestionnaireAnswers.length > 0) {
                element.subQuestionnaireAnswers.find((subQuestion) => {
                  if (subQuestion.questionCode === 'SQC-100040-9')
                  {
                    subQuestion.answer = JSON.stringify(getApartmentNumberIdentifierKey(JSON.parse(subQuestion.answer)));
                  }
                });
              }}});
            answer[i] = final;
            this.postAccountQuestion('account', final, product, currentStep, currentIndex, appId)
            const data = {
                accountAnswer: {
                    accountAnswer: answer,
                }
            }
            return data;
        });
    }


    addUltimateFormData(ultimateFormValue: any, currentStep, currentIndex, appId, exitStep) {
        this.patchState((state: UltimatePageState) => {
            const data = {
                ultimate: {
                    applicationId: Math.floor(ultimateFormValue[2]),
                    organizationId: ultimateFormValue[3].toString(),
                    beneficialOwnershipType: ultimateFormValue[4],
                    beneficialOwnershipTypeId: ultimateFormValue[1].toString(),
                    beneficiaryOwnershipFlag: ultimateFormValue[5],
                    taxExemptCode: ultimateFormValue[0].toString(),
                    taxExempted: ultimateFormValue[0].toString().length ? true : false,
                    uboChanged: true,
                    uboPreviouslyCollected: false
                }
            }
            this.postUltimateData(data?.ultimate, currentStep, currentIndex, appId, exitStep);
            return data;
        });
    }


    getProducts(number) {
        this.effect(() => {
            return this.preApplicationService.getProducts(number).pipe(
                tapResponse(
                    (data) => {
                        this.patchState(() => ({
                            products: {
                                products: data,
                                isDataLoaded: true,
                                dataError: false,
                            }
                        }))
                    },
                    (error: any) => {
                        this.patchState(() => ({
                            products: {
                                products: null,
                                isDataLoaded: false,
                                dataError: error,
                            }
                        }))
                    }
                )
            )
        })
    }

    setPayLoadCoApplicants(data) {
        var usPerson;
        if(data.usPerson){if(data?.usPerson === 'No'){usPerson = false}else{usPerson=true}}else{usPerson=null}
        var payload = {
            relationShipToCompany: data.relationShipToCompany || '',
            professionalTitle: data.relationShipToCompany || '',
            firstName: data.firstName || '',
            middleName: data.middleName || '',
            lastName: data.lastName || 'N/A',
            existingCustomer: data.existingCustomer,
            dateOfBirth: data.dateOfBirth || '',
            primaryApplicant: false,
            shortName: data.lastName + ' ' + data.firstName,
            isKYCNeed: true,
            percentageOfCompany: data.percentageOfCompany || '',
            trustName: data.trustName || undefined,
            usPerson: usPerson,
            customerIdentity: data.customerIdentity === "" ? [] : data.customerIdentity,
            citizenship: data.citizenship || null,
            customerId: data.customerId || null,
            customerType: data.customerType || "business",
            socialSecurityNbr: data.socialSecurityNbr,
            relationshipCode: data.relationshipCode ? data.relationshipCode : data?.profileType && data?.profileType?.length > 0 ? this.getRelationshipCode(data.profileType) : undefined,
            address: {
                id: data.id,
                address1: data.address1 || "",
                address2: data.address2 || "",
                city: data.city || "",
                state: data.state || "",
                country: data.country || "",
                countryAlpha2: 'US',
                countryAlpha3: 'USA',
                stateCode: data.stateCode || 'TX',
                zipCode: data.zipCode || "",
                mobilePhoneNbr: data.mobilePhoneNbr || "",
                homePhoneNbr: data.homePhoneNbr,
                customerBusinessPhone: data.customerBusinessPhone || "",
                businessEmail: data.businessEmail || "",
                personalEmail: data.personalEmail || "",
                businessPhone: data.customerBusinessPhone,
                usAddress: true,
                apartmentNumberId: getApartmentNumberIdentifierKey(data.apartmentNumberId?.trim()) || undefined
            },
            customerCif: data.customerCif,
            ccn: data.ccn,
            modifiedDate: data.modifiedDate
        }
        return payload;
    }

    getRelationshipCode(profileType: string): string {
      return profileType.toLowerCase() == 'administrator' ? 'ADM' : 'EXE';
    }

    putProduct(data, currentStep, currentIndex, appId: any) {
        this.effect(() => {
            return this.preApplicationService.putProduct(data, appId).pipe(
                tapResponse(
                    (data: any) => {
                        this.putApplicationStep(currentStep, currentIndex, false);
                        this.patchState(() => ({
                            products: {
                                products: data?.accounts,
                                isDataLoaded: true,
                                dataError: false,
                            }
                        }))
                    },
                    (error: any) => {
                        this.patchState(() => ({
                            products: {
                                products: null,
                                isDataLoaded: false,
                                dataError: error,
                            }
                        }))
                    }
                )
            )
        })
    }

    saveNickname(appId, products, currentStep, currentIndex) {
        this.patchState((state: ApplicationPageState) => {
            var payload = {
                application: {
                    accounts: products,
                    applicationId: appId
                }
            }
            this.putProduct(payload, currentStep, currentIndex, appId);
            return {}
        })
    }

    updateOrganizationValues(updatedProfileInfo: any, applicationId: any) {
        this.effect(() => {
            return this.preApplicationService.updateProfile(updatedProfileInfo, applicationId).pipe(
                tapResponse((data: any) => {
                        this.patchState(() => ({
                            profileInfo: {
                                profileInfo: data.application,
                                isDataLoaded: true,
                                dataError: false,
                            }
                        }))
                    },
                    (error: any) => {
                        this.patchState((state: ApplicationPageState) => ({
                            profileInfo: {
                                profileInfo: state.profileInfo,
                                isDataLoaded: false,
                                dataError: error,
                            }
                        }))
                    }
                )
            )
        })
    }


    readonly profileInformation$: Observable<any> = this.select((state) => state.profileInfo.profileInfo);
    readonly uploadedDocuments$: Observable<any> = this.select((state) => state.uploadedDocuments);
    readonly businessProfileQuestions$: Observable<any> = this.select((state) => state.questions.businessProfileQuestions);
    readonly geographyQuestions$: Observable<any> = this.select((state) => state.questions.geographyQuestions);
    readonly regulatoryQuestions$: Observable<any> = this.select((state) => state.questions.regulatoryQuestions);
    readonly securitiesQuestions$: Observable<any> = this.select((state) => state.questions.securitiesQuestions);
    readonly mbsTbaQuestions$: Observable<any> = this.select((state) => state.questions.mbsTbaQuestions);
    readonly estateRelatedQuestions$: Observable<any> = this.select((state) => state.questions.estateRelatedQuestions);
    readonly trustRelatedQuestions$: Observable<any> = this.select((state) => state.questions.trustRelatedQuestions);
    readonly additionalBusinessQuestions$: Observable<any> = this.select((state) => state.questions.additionalBusinessQuestions);
    readonly businessProfileAnswers$: Observable<any> = this.select((state) => state.answers.businessProfileAnswers);
    readonly geographyAnswers$: Observable<any> = this.select((state) => state.answers.geographyAnswers);
    readonly estateAnswers$: Observable<any> =this.select((state) => state.answers.estateRelatedAnswers);
    readonly trustAnswers$: Observable<any> =this.select((state) => state.answers.trustRelatedAnswers);
    readonly regulatoryAnswers$: Observable<any> = this.select((state) => state.answers.regulatoryAnswers);
    readonly securitiesAnswers$: Observable<any> = this.select((state) => state.answers.securitiesAnswers);
    readonly mbsTbaAnswers$: Observable<any> = this.select((state) => state.answers.mbsTbaAnswers);
    readonly additionalBusinessAnswers$: Observable<any> = this.select((state) => state.answers.additionalBusinessAnswers);
    readonly isDataLoadedAnswers$: Observable<any> = this.select((state) => state.answers.isDataLoaded);
    readonly coapplicants$: Observable<any> = this.select((state) => state.additionalProfiles.coapplicants);
    readonly controllingParty$: Observable<any> = this.select((state) => state.additionalProfiles.controllingParty);
    readonly trustOwnershipInterest$: Observable<any> = this.select((state) => state.additionalProfiles.trustOwnershipInterest);
    readonly ownerShipInterest$: Observable<any> = this.select((state) => state.additionalProfiles.ownershipInterest);
    readonly authorizedSigner$: Observable<any> = this.select((state) => state.additionalProfiles.authorizedSigner);
    readonly authorizedTrader$: Observable<any> = this.select((state) => state.additionalProfiles.authorizedTrader);
    readonly ultimateBeneficialOwnership$: Observable<any> = this.select((state) => state.ultimate);
    readonly loadAccountQuestions$: Observable<any> = this.select((state) => state.loadAccountQuestions.accountQuestions);
    readonly accountAnswer$: Observable<any> = this.select((state) => state.accountAnswer.accountAnswer);
    readonly fullAccountAnswers$: Observable<any> = this.select((state) => state.fullAccountAnswers);
    readonly estateData$: Observable<any> = this.select((state) => state.estateData);
    readonly trustData$: Observable<any> = this.select((state) => state.trustData);
    readonly products$: Observable<any> = this.select((state) => state.products.products);
    readonly navigation$: Observable<any> = this.select((state) => state.navigation.navigationData);
}
