import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttpService } from './api-http.service';

@Injectable({
  providedIn: 'root'
})
export class DashBoardService {
    commercialUrl = `commercial-core/v1/commercial/on-board/application`;


    constructor(private apiHttpService: ApiHttpService) {}

    updateApplicationStatus(
        status: string,
        application: any,
        comments: string,
        applicationStep: string,
        pageStage: string,
        currentUser?:  any | null,
        approvedAt = '',
        approvedBy = '',
        customStep = ''
    ): Observable<any> {
        const currentStatus = status === 'PENDING WITH REVIEWER' || status === 'PENDING WITH CUSTOMER' ? 'navigation' : status;
        const payload = {
            applicationId: application.applicationId || application.id,
            applicationStep: customStep || applicationStep || application.applicationStep,
            applicationStage: 'ANALYST REVIEW',
            applicationStatus: currentStatus,
            updatedAt: new Date(),
            updatedBy: currentUser?.name,
            uboSigned: true,
            cmdaSigned: true,
            comments: comments,
            pageStage: pageStage || application.pageStage,
            approvedAt: approvedAt || application.approvedAt,
            approvedBy: approvedBy || application.approvedBy,
            statusChange: true
        };
        return this.apiHttpService.put(this.commercialUrl + '/' + (application.applicationId || application.id) +  '/status', payload);
    }

}
