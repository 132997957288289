import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ApplicationFlow } from '@app/models/application-flow';
import { Views } from '@app/models/views';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadCrumbComponent implements OnInit {
  @Input() showApplicationProfile: boolean;
  @Output() output: any = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  navigateToPre() {
    this.output.emit({
      parentView: Views.profile,
      subView: ApplicationFlow.application,
      activeIndex: 0
    });
  }

  navigateToApplication() {
    this.output.emit({
      parentView: Views.application,
      subView: ApplicationFlow.businessProfile,
      activeIndex: 0
    });
  }
  navHome() {
    window.location.href = 'https://www.texascapitalbank.com/commercial-banking';
  }


}
