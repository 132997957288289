import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { SampleModel } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class SampleServiceService {

  private sampleUrl: string = "https://jsonplaceholder.typicode.com";

  constructor(private httpClient: HttpClient) { }

  get(): Observable<SampleModel> {
    return this.httpClient.get<SampleModel>(`${this.sampleUrl}/posts`)
      .pipe(catchError(this.handleError));;
  }

  post(data): Observable<SampleModel> {
    return this.httpClient.post<SampleModel>(`${this.sampleUrl}/posts`,data)
      .pipe(catchError(this.handleError));;
  }

  handleError(error: any) {
    let errorMessage = '';
    (error.error instanceof ErrorEvent) ? errorMessage = `ClienSideError: ${error.error.message}` : errorMessage = `ServerSide Error Code: ${error.status}\nMessage: ${error.message}`;
    return throwError(() => { return errorMessage });
  }
}
