import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppFacade } from '@app/core';
import { VerificationService } from '@app/core/services/verification.service';
import { ProfileCreationStore } from '@app/views/profile-creation/profile-creation.store';
import { Views } from '@app/models/views';
import { ToastrService } from '@app/core/services/toastr.service';
import { environment } from '@env/environment';
import { NotificationService } from '@app/core/services/notification.service';
import { Location } from '@angular/common';
import { ApplicationStore } from '@app/views/application/application.page.store';
import { ApplicationFlow } from '@app/models/application-flow';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-verify-application',
  templateUrl: './verify-application.component.html',
  styleUrls: ['./verify-application.component.scss'],
})
export class VerifyApplicationComponent implements OnChanges {
  @Input() token: any;
  @Input() email: any;
  @Input() bsaProfile: any;
  @Input() activationCode: any;
  @Input() searchParams: any;
  @Input() customerId: any;
  @Input() applicationId: any;
  @Output() output: any = new EventEmitter();
  userinfo: any;
  attemptCount: number;
  view = Views;
  otp: any;
  showUserScreen: boolean = true;
  showOTPScreen: boolean = false;
  destroy$ = new Subject();



  constructor(
    private router: Router, 
    private readonly profileCreation: ProfileCreationStore,
    private readonly applicationStore: ApplicationStore,
    private verificationService: VerificationService, 
    private toastrService: ToastrService, 
    private appFacade: AppFacade, 
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    private location: Location
  ) {}

  ngOnChanges(changes: any) {
    this.userinfo = this.createFormGroup();
    if (this.email || changes.applicationId?.currentValue) {
      if (changes.email) {
        this.email = changes.email?.currentValue;
        this.token = changes.token?.currentValue;
        this.activationCode = changes.activationCode?.currentValue ?? '';
        this.bsaProfile = changes.bsaProfile?.currentValue;
        this.searchParams = changes.searchParams?.currentValue;
        this.applicationId = changes.applicationId?.currentValue;
        this.profileCreation.attemptCount$.subscribe((attemptCount) => this.attemptCount = attemptCount);
        if (!environment.oktaLocal) { (this.searchParams) ? this.router.navigate([`${this.applicationId}`], { queryParams: {} }) : this.router.navigate([`${this.applicationId}`])}
      } else {
        this.router.navigate([`${this.applicationId}`]);
      }
    } else {
      this.router.navigate(['/home']);
    }
  }

  createFormGroup(): any {
    let fg = new FormGroup({
      tin: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      mobileNumber: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      applicationNumber: new FormControl(null, [Validators.required]),
      otp: new FormControl(''),
    });
    fg.patchValue({ applicationNumber: (!isNaN(parseFloat(this.applicationId)))?this.applicationId: ''});
    fg.patchValue({ email: this.email })
    return fg;
  }

  submitUser() {
    if (!environment.isLocal) {
      if (this.userinfo.valid) {
        document.cookie = `email=${this.userinfo.get('email').value}`;
        this.notificationService.generateToken().subscribe((res)=>{
          this.notificationService.getCustomerInfo(this.userinfo.get('applicationNumber').value,this.userinfo.get('email').value).subscribe((response) => {
            this.token = response?.activationToken;
            this.customerId = response?.customerUserId;
            document.cookie = `application-id=${this.userinfo.get('applicationNumber').value}`;
            document.cookie = `session-guid=${this.token}`;
            document.cookie = `ip-address=invalid`
            this.verify();
          },(error:any)=>{
            this.toastrService.error('Generation Failed');
          })
        })
      } else {
        this.toastrService.error('Please provide valid information');
      }
    } else {
      this.appFacade.getBsaProfile();
      this.applicationStore.getNavData();
      this.appFacade.getStates(this.userinfo.get('applicationNumber').value);
      this.appFacade.getCountries(this.userinfo.get('applicationNumber').value);
      this.appFacade.getNaicsCodes(this.userinfo.get('applicationNumber').value);
      this.appFacade.getEntityList(this.userinfo.get('applicationNumber').value);
      combineLatest([this.applicationStore.navigation$, this.appFacade.getBsaProfile$]).pipe(
        distinctUntilChanged((prev: any, next: any) => JSON.stringify(prev) === JSON.stringify(next)),
        takeUntil(this.destroy$)
      ).subscribe(([navData, bsaProfile]) => {
        if(bsaProfile?.applicationId) {
          this.handleView(navData);
        }
      });
    }
  }


  onOtpChange($event) {
    this.otp = $event;
  }


  submitOtp(userinfo) {
    var payload = { applicationId: userinfo.value.applicationNumber, email: userinfo.value.email, activationCode: this.otp }
    this.verificationService.validateCustomer(payload, this.userinfo.get('applicationNumber').value).subscribe((res) => {
      this.appFacade.getBsaProfile();
      this.applicationStore.getNavData();
      this.appFacade.getStates(this.userinfo.get('applicationNumber').value);
      this.appFacade.getCountries(this.userinfo.get('applicationNumber').value);
      this.appFacade.getNaicsCodes(this.userinfo.get('applicationNumber').value);
      this.appFacade.getEntityList(this.userinfo.get('applicationNumber').value);
      combineLatest([this.applicationStore.navigation$, this.appFacade.getBsaProfile$]).pipe(
        distinctUntilChanged((prev: any, next: any) => JSON.stringify(prev) === JSON.stringify(next)),
        takeUntil(this.destroy$)
      ).subscribe(([navData, bsaProfile]) => {
        if(bsaProfile?.applicationId) {
          this.handleView(navData);
        }
      });
      this.cd.detectChanges();
    },(error)=>{
      this.toastrService.error('Please provide a valid activation code.');
    })
  }

  constructVericationPayload(verificationAnswers: any): any {
    return {
      customerUserId: this.customerId,
      applicationId: parseFloat(this.userinfo.get('applicationNumber').value),
      activationToken: this.token,
      createdDate: this.bsaProfile?.createdAt,
      createBy: this.bsaProfile?.createBy,
      activationDate: new Date(),
      firstName: this.bsaProfile?.applicant?.firstName,
      lastName: this.bsaProfile?.applicant?.lastName,
      email: this.userinfo.get('email').value,
      mobileNumber: this.userinfo.get('mobileNumber').value,
      isUserActivated: true,
      activationTryCount: verificationAnswers.attemptCount,
      userIdentity: this.userinfo.get('tin').value
    };
  }

  handleView(navigationData: any[]) {
    if(!navigationData[0]) {
      this.output.emit({
        parentView: Views.profile,
        subView: ApplicationFlow.application,
        activeIndex: 0,
      });
    } else {
      const isProfile = navigationData[0].applicationStep?.includes('applicant');
      if(!isProfile) {
        this.output.emit({
          parentView: Views.application,
          subView: navigationData[0].applicationStep.split('/')[3],
          activeIndex: 0,
        });
      } else {
        this.output.emit({
          parentView: Views.profile,
          subView: navigationData[0].applicationStep.split('/')[3] === 'preapplication' ? ApplicationFlow.preapplication :  ApplicationFlow.application,
          activeIndex: 0,
        });
      }
    }
  }


  verify() {
    this.verificationService.verifyCustomer(this.constructVericationPayload(event), this.userinfo.get('applicationNumber').value).subscribe((res) => {
      this.location.go(`/${this.userinfo.get('applicationNumber').value}`);
      this.showOTPScreen = true;
      this.showUserScreen = false;
      this.output.emit(undefined);
      this.cd.detectChanges();
      this.toastrService.success('Verification Success');
    },(error)=>{
      this.toastrService.error('Verification Failed. Please Contact Administrator');
    });
  }

  ngOnDestroy() {
    this.destroy$.next({});
    this.destroy$.complete();
  }
}
