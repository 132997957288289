import { Component, Input, OnChanges, OnDestroy, Output, EventEmitter, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppFacade, GlobalService } from '@app/core';
import { combineLatest, distinctUntilChanged, Subject } from 'rxjs';
import { isEqual } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from '@app/core/services/toastr.service';
import { Router } from '@angular/router';
import { ApplicationStore } from '@app/views/application/application.page.store';
import { getApartmentIdentifierValue } from '@app/models/apartmentnumberIdentifiers';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() questionandAnswers: any;
  @Input() subCatagories: string[];
  @Input() nextStep: string;
  @Input() trigger;
  @Input() questionSection: string;
  @Input() isDataLoadedAnswers: boolean;
  @Input() prevStep: string;
  @Input() currentStep: number;
  @Input() currentIndex: number;
  destroy$ = new Subject();
  states: any[];
  countries: any[];
  naicsCodes: any[];
  entityList: any[];
  @Output() answers = new EventEmitter();
  dynamicForm = new FormGroup({});
  public dynamicQA: FormGroup = this.fb.group({});
  questions: any;
  detectScroll$;
  saveExitModal: boolean = false;
  maxDate;
  appStatus: any;
  supressedIbQuestions: string[] = ['QC-1077', 'QC-1078', 'QC-1080', 'QC-1081', 'QC-1082', 'QC-1083', 'QC-1084', 'QC-10012', 'QC-1052', 'QC-1085', 'QC-1086'];
  supressedCommercialQuestions: string[] = ['QC-1015', 'QC-1020', 'QC-1021'];
  lobType: string = '';

  constructor(
    private fb: FormBuilder,
    private appFacade: AppFacade,
    private toastrService: ToastrService,
    private globalService: GlobalService,
    private router: Router,
    private gs: GlobalService,
    private applicationStore: ApplicationStore
  ) {
    this.detectScroll$ = this.globalService.detectScroll$;
    this.maxDate = new Date();
      if(this.maxDate) {
       var year = this.maxDate.getFullYear();
       var month = this.maxDate.getMonth() + 1;
       var dt = this.maxDate.getDate();
       dt = dt - 1;
       if (dt < 10) {
         dt = '0' + dt;
       }
       if (month < 10) {
         month = '0' + month;
       }
       this.maxDate = year + '-' + month + '-' + dt;
      }
  }

  ngOnChanges(changes: any) {
    combineLatest([this.appFacade.states$, this.appFacade.countries$, this.appFacade.naicsCodes$, this.appFacade.entityList$, this.appFacade.lobType$]).pipe(distinctUntilChanged((prev, next) => isEqual(prev, next)), takeUntil(this.destroy$)).subscribe(([states, countries, naicsCodes, entityList, lobType]) => {
      this.states = states;
      this.countries = countries;
      this.naicsCodes = naicsCodes;
      this.entityList = entityList;
      this.lobType = lobType;
    });
    this.createGroup(changes.questionandAnswers?.currentValue[0], changes.questionandAnswers?.currentValue[1]);
    this.createForm(changes.questionandAnswers?.currentValue[0], changes.questionandAnswers?.currentValue[1]);

    (this.dynamicQA.get('qa') as FormArray).controls.filter((control) => {
      return control.get('displayQuestion').value === 'Country';
    })?.forEach((countryControl) => {
      if(countryControl.get('answer').value && countryControl.get('answer').value.trim() !== 'United States') {
        this.hideUsQuestions(countryControl.get('subCategory').value, countryControl.get('subCategory').value === 'MAILING ADDRESS');
      }
      if(!countryControl.get('answer').value || countryControl.get('answer').value.trim() === 'United States') {
        this.hideNonUsQuestions(countryControl.get('subCategory').value, countryControl.get('subCategory').value === 'MAILING ADDRESS')
      }
    });

    (this.dynamicQA.get('qa') as FormArray).controls.forEach((control) => {
      if (control.get('subQuestionnaireAnswers').value.length) {
        control.get('subQuestionnaireAnswers').value.forEach((subQ) => {
          if (subQ.displayQuestion === 'Country') {
            if(subQ.answer && subQ.answer.trim() !== 'United States') {
              this.hideUsSubQuestions(control);
            }
            if(!subQ.answer || subQ.answer.trim() === 'United States') {
              this.hideNonUsSubQuestions(control);
            }
          }
        })
      }
    });
  }

  ngOnInit(): void {
    this.applicationStore.profileInformation$.subscribe((profileInfo) => {
      this.appStatus = profileInfo.applicationStatus;
    });
  }

  setTCBUIAttribute(IdentifierName: string): string {
    return IdentifierName.split(" ").join("-");
  }

  changeTimeZone(date, timeZone) {
    return new Date(
      new Date(date).toLocaleString('en-US', {
        timeZone,
      }),
    );
  }

  createGroup(data: any, answers: any) {
    data?.forEach((element, i) => {
      this.dynamicForm.addControl(element.questionCode, new FormControl({ disabled: element.disabled, value: '' }, [Validators.maxLength(element.maxLength)]), element.questionType);
      this.dynamicForm.get(element.questionCode).setValidators(this.getValidators(element));
    });
    return this.dynamicForm;
  }

  daysInThisMonth(date) {
    var now = new Date(date);
    return new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
  }
  
  setQuestionAns(element, type) {
    var value: any = { value: { usAddress: false } };
    var additionalSpec, possibleValues, dropdownSettings, position, extPosition, answer, expectedAnswers, tooltip, mask, parentQuestionAnswer, maxDateRestrict, minDateRestrict;
    (element?.extAdditionalSpec) ? additionalSpec = element?.extAdditionalSpec : additionalSpec = '{}';
     if (element.questionCode === 'SQC-100040-9' && element?.answer) {
       answer = ' ' + getApartmentIdentifierValue(JSON.parse(element?.answer)) + ' ';
     } else {
      answer = (element?.answer) ? [JSON.parse(element?.answer)] || element.answer : '';
    }
    if (element.questionType == 'date-picker') {
      var questionAndAnswer = answer;
      answer = this.changeTimeZone(questionAndAnswer,'America/Chicago');
      var year = answer.getFullYear();
      var month = answer.getMonth() + 1;
      var dt = answer.getDate()+1;

     //get the days in current month and check if it is > days of a month got from data date(ex : 32>31 then date = 01)
      var daysInCurrentMonth = this.daysInThisMonth(answer);

      if (dt > daysInCurrentMonth) {
        dt = 1;
        month = month < 12 ? month + 1 : 1;
      }

      var beforeFormattedYear = new Date(questionAndAnswer).getFullYear();
      var afterFormattedYear = answer.getFullYear();
      if (afterFormattedYear < beforeFormattedYear) {
        year = year + 1;
      }

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      answer = year + '-' + month + '-' + dt;
    }
    var possibleValues = this.modifyQuestions(element);
    if (possibleValues !== null) {
      if (typeof possibleValues === 'string') { possibleValues = [possibleValues.split('|')]; }
    } else { possibleValues = []; }
    maxDateRestrict = JSON.parse(element.extAdditionalSpec)?.maxDateRestrict;
    minDateRestrict = JSON.parse(element.extAdditionalSpec)?.minDateRestrict;
    if (type === 'main') {
      dropdownSettings = { singleSelection: JSON.parse(additionalSpec)?.dropdownSettings ? JSON.parse(additionalSpec)?.dropdownSettings?.singleSelection : '' };
      position = JSON.parse(additionalSpec)?.position ? JSON.parse(additionalSpec)?.position : [[]];
      extPosition = 0;
      expectedAnswers = JSON.parse(additionalSpec)?.expectedAnswers ? [JSON.parse(additionalSpec)?.expectedAnswers] : [[]];
      parentQuestionAnswer = JSON.parse(additionalSpec)?.parentQuestionAnswer ? [JSON.parse(additionalSpec)?.parentQuestionAnswer] : [[]]
      tooltip = JSON.parse(element.extAdditionalSpec)?.tooltip;
      mask = JSON.parse(element.extAdditionalSpec)?.mask;
    }


    if (type === 'sub') {
      expectedAnswers = JSON.parse(element.additionalSpec).expectedAnswers ? [JSON.parse(element.additionalSpec).expectedAnswers] : [[]];
      position = JSON.parse(element.additionalSpec)?.position ? JSON.parse(element.additionalSpec)?.position : [[]];
      extPosition = JSON.parse(element.extAdditionalSpec)?.position ? JSON.parse(element.extAdditionalSpec)?.position : [[]];
      dropdownSettings = { singleSelection: JSON.parse(element.additionalSpec)?.dropdownSettings ? JSON.parse(element.additionalSpec)?.dropdownSettings?.singleSelection : '' };
      expectedAnswers = JSON.parse(element.additionalSpec)?.expectedAnswers ? [JSON.parse(element.additionalSpec)?.expectedAnswers.map((ele) => ele.trim())] : [[]];
      parentQuestionAnswer = JSON.parse(element.additionalSpec)?.parentQuestionAnswer ? [JSON.parse(element.additionalSpec)?.parentQuestionAnswer] : [[]]
      mask = JSON.parse(element.extAdditionalSpec)?.mask;
    }

    if (element.questionType === 'ngselect' && dropdownSettings.singleSelection) {
      expectedAnswers[0] = expectedAnswers[0].map((expectedAnswer) => ' ' + expectedAnswer + ' ');
      answer = answer[0] && Array.isArray(answer[0]) ? ' ' + answer.join(',') + ' ' : answer[0] ? answer : '';
    }

    //Hiding IB specific questions for Commercial
    if (this.lobType != 'Investment_Banking') {
      if (this.supressedIbQuestions.includes(element.questionCode)) {
        element.mandatory = false;
        element.displayable = false;
      }
    }

    //Hiding Commercial specific questionsfor IB
    if (this.lobType == 'Investment_Banking') {
      if (this.supressedCommercialQuestions.includes(element.questionCode)) {
      element.mandatory = false;
      element.displayable = false;
    }
    }
    
    if (element.questionType === 'ngselect' && !dropdownSettings.singleSelection) {
      expectedAnswers[0] = expectedAnswers[0].map((expectedAnswer) => ' ' + expectedAnswer + ' ');
      answer = answer[0] ? answer[0].join('|') : '';
      var arr = answer.split('|');
      var newele = arr.map((ele) => ele.trim());
      newele = newele.map((ele) => ' ' + ele + ' ')
      answer = answer ? [newele] : [[]];
    }

    return {
      addressContactDTO: value,
      answer: answer[0] === 'null' ? '' : answer,
      questionCode: element.questionCode,
      id: element.id,
      questionDescription: element?.questionDescription,
      active: element.active,
      extAdditionalSpec: element.extAdditionalSpec,
      placeholder: JSON.parse(additionalSpec)?.placeholder ?? '',
      additionalSpec: element.additionalSpec,
      answersDefaults: element.answersDefaults,
      displayQuestion: element.displayQuestion,
      displayable: element.displayable,
      mandatory: element.mandatory,
      profileCategory: element.profileCategory,
      profileId: element.profileId,
      profileType: element.profileType,
      questionType: element.questionType,
      profileDescription: element.profileDescription,
      subCategory: element.subCategory,
      possibleValues: possibleValues,
      profileTitle: element.profileTitle,
      parentId: element.parentId,
      subParentId: element?.subParentId,
      expectedAnswers: expectedAnswers,
      parentQuestionAnswer: parentQuestionAnswer,
      dropdownSettings: dropdownSettings,
      position: position,
      extPosition: extPosition,
      width: JSON.parse(additionalSpec)?.gridSize ? [JSON.parse(additionalSpec)?.gridSize] : [],
      tooltip: tooltip,
      mask: mask,
      maxDateRestrict: maxDateRestrict,
      minDateRestrict: minDateRestrict
    }
  }

  getDateValidity(condition: string) {
    switch (condition) {
      case 'today':
        return this.formatDate(new Date());
        break;
      case 'tomorrow':
        return this.formatDate(new Date(new Date().setDate(new Date().getDate() + 1)));
        break;
      case '18yearsago':
        return this.formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 18)));
        break;
      case 'start1800':
        return this.formatDate(new Date(1800, 0, 1));
        break;
      case 'start120':
        return this.formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 120)));
        break;
    }
    return null;
  }
  formatDate (dateToFormat) {
    if(dateToFormat) {
      var year = dateToFormat.getFullYear();
      var month = dateToFormat.getMonth() + 1;
      var dt = dateToFormat.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
       return year + '-' + month + '-' + dt;
     }
  }
  createForm(data: any, answers: any) {
    var value: any = { value: { usAddress: false } }
    this.dynamicQA.addControl('qa', new FormArray([]));
    var finalAnswers;
    var final = []
    let control = <FormArray>(this.dynamicQA.controls.qa);
    // control?.setValue([]);
    for (let i = control.controls.length - 1; i >= 0; i--) {
      control.removeAt(i)
    }
    if (answers === null || answers?.length === 0) {
      for (var i = 0; i < data.length; i++) {
        var ques = { ...data[i], subQuestionnaireAnswers: data[i].subQuestionnaires }
        final.push(ques);
      }
      finalAnswers = final?.sort((a, b) => (JSON.parse(a?.extAdditionalSpec)?.position) - (JSON.parse(b?.extAdditionalSpec)?.position));
      finalAnswers?.forEach((element, i) => {
        control.push(
          this.fb.group({
            ...this.setQuestionAns(element, 'main'),
            subQuestionnaireAnswers: this.setSubQuestionsAnswers(element.subQuestionnaireAnswers)
          })
        );
        (this.dynamicQA.get('qa') as FormArray).controls[i].get('answer').setValidators(this.getValidators(element))
      })
    } else {
      for (var i = 0; i < answers.length; i++) {
        for (var j = 0; j < data.length; j++) {
          if (answers[i]?.questionCode === data[j].questionCode) {
            var subq = (answers[i]?.subQuestionnaireAnswers) ? answers[i]?.subQuestionnaireAnswers : [];
            var subans = []
            var subques = [];
            subq.forEach(element => { subans.push({ ...element }) });
            data[j].subQuestionnaires.forEach((ele, i) => { subques.push({ ...ele }) });
            var sortedSub = subques.sort((a, b) => (JSON.parse(a?.additionalSpec)?.position) - (JSON.parse(b?.additionalSpec)?.position));
            subans.sort((a, b) => {
              const indexA = sortedSub.findIndex(type => a.questionCode === type.questionCode);
              const indexB = sortedSub.findIndex(type => b.questionCode === type.questionCode);
              return indexA - indexB;
            });
            const newArray = sortedSub.map((x, k) => {
              var item = subans.find(item => item.questionCode === x.questionCode);
              return ({ ...x, answer: (item?.answer) ? (item?.answer) : '' });
            }).filter(item => item !== undefined);
            var ans = { ...answers[i], ...data[j], subAns: newArray }
            final.push(ans)
          }
        }
      }
      final.find((ele) => { if (ele.questionCode === 'QC-1055' || ele.questionCode === 'QC-1056') {
        ele.answer = ele.answer ? JSON.stringify(getApartmentIdentifierValue(JSON.parse(ele.answer))) : '';
      } });

      finalAnswers = final.sort((a, b) => a.position - b.position);
      finalAnswers = finalAnswers.sort((a, b) => (JSON.parse(a?.extAdditionalSpec)?.position) - (JSON.parse(b?.extAdditionalSpec)?.position));
      finalAnswers?.forEach((element, i) => {
        control.push(
          this.fb.group({
            ...this.setQuestionAns(element, 'main'),
            subQuestionnaireAnswers: this.setSubQuestionsAnswers(element.subAns)
          })
        );
        (this.dynamicQA.get('qa') as FormArray).controls[i].get('answer').setValidators(this.getValidators(element))
      })
    }
  }

  setSubQuestionsAnswers(subQuestions) {
    var finalAnswers;
    var value: any = { value: { usAddress: false } };
    let arr = new FormArray([]);
    var arrayForSort = [...subQuestions]
    finalAnswers = arrayForSort?.sort((a, b) => (JSON.parse(a?.additionalSpec)?.position) - (JSON.parse(b?.additionalSpec)?.position));
    finalAnswers?.forEach((element, i) => {
      arr.push(
        this.fb.group({
          ...this.setQuestionAns(element, 'sub'),
        })
      );
    });
    return arr;
  }

  getQA(form) {
    return form.controls.qa.controls;
  }

  hideNonUsQuestions(subCategory: string, isOptional: boolean) {
    (this.dynamicQA.get('qa') as FormArray).controls.forEach((control) => {
      if((control.get('displayQuestion').value.toLowerCase() === 'zip code' || control.get('displayQuestion').value === 'State') && control.get('subCategory').value.toLowerCase() === subCategory.toLowerCase()) {
        control.get('displayable').patchValue(true);
        if(!isOptional) {
          control.get('answer').setValidators(Validators.required);
        }
      }
      if((control.get('displayQuestion').value === 'Postal Code' || control.get('displayQuestion').value === 'State/Province/Territory') && control.get('subCategory').value.toLowerCase() === subCategory.toLowerCase()) {
        control.get('displayable').patchValue(false);
        control.get('answer').removeValidators(Validators.required);
      }
    });
  }

  hideUsQuestions(subCategory: string, isOptional: boolean) {
    (this.dynamicQA.get('qa') as FormArray).controls.forEach((control) => {
      if((control.get('displayQuestion').value.toLowerCase() === 'zip code' || control.get('displayQuestion').value === 'State') && control.get('subCategory').value.toLowerCase() === subCategory.toLowerCase()) {
        control.get('displayable').patchValue(false);
        control.get('answer').removeValidators(Validators.required);
      }
      if((control.get('displayQuestion').value === 'Postal Code' || control.get('displayQuestion').value === 'State/Province/Territory') && control.get('subCategory').value.toLowerCase() === subCategory.toLowerCase()) {
        control.get('displayable').patchValue(true);
        if(!isOptional) {
          control.get('answer').setValidators(Validators.required);
        }
      }
    });
  }

  hideNonUsSubQuestions(question: any) {
    let indexOfQuestion = (this.dynamicQA.get('qa') as FormArray).controls.findIndex((control) => control.value.questionCode === question.value.questionCode);
    ((this.dynamicQA.get('qa') as FormArray).controls[indexOfQuestion].get('subQuestionnaireAnswers') as FormArray).controls.forEach((control) => {
      if (control.get('displayQuestion').value === 'Postal Code' || control.get('displayQuestion').value === 'State/Province/Territory') {
        control.get('displayable').patchValue(false);
        control.get('answer').removeValidators(Validators.required);
      }
      if (control.get('displayQuestion').value.toLowerCase() === 'zip code' || control.get('displayQuestion').value === 'State') {
        control.get('displayable').patchValue(true);
      }
    });
  }

  hideUsSubQuestions(question: any) {
    let indexOfQuestion = (this.dynamicQA.get('qa') as FormArray).controls.findIndex((control) => control.value.questionCode === question.value.questionCode);
    ((this.dynamicQA.get('qa') as FormArray).controls[indexOfQuestion].get('subQuestionnaireAnswers') as FormArray).controls.forEach((control) => {
      if (control.get('displayQuestion').value.toLowerCase() === 'zip code' || control.get('displayQuestion').value === 'State') {
        control.get('displayable').patchValue(false);
        control.get('answer').removeValidators(Validators.required);
      }
      if (control.get('displayQuestion').value === 'Postal Code' || control.get('displayQuestion').value === 'State/Province/Territory') {
        control.get('displayable').patchValue(true);
      }
    });
  }

  getSubQuestions(form) {
    return form.controls.subQuestionnaireAnswers.controls;
  }

  validateParentQuestions(expectedAnswers: any, answer: any, question: any) {

    //checking Customer's Business
    let questionCode = question.get('questionCode').value;
    if(questionCode == 'QC-1023' && answer == 'Publicly Traded'){
      return true;
    }
    
    if((Array.isArray(answer)) ? answer.find((ans) => expectedAnswers.includes(ans)) : expectedAnswers.includes(answer)) {
      return true
    } else {
      question.controls.subQuestionnaireAnswers.controls.forEach((ele)=>{
        ele.get('answer').removeValidators(Validators.required);
        ele.get('answer').setValue('');
        ele.get('answer').setErrors(null);
      })
      return false
    }
  }

  validateSubQuesVisibile(subquestion, question) {
    const subParentId = subquestion.get('subParentId').value;
    if (subquestion.get('parentQuestionAnswer').value.includes('|')) {
      subquestion.get('parentQuestionAnswer').value = subquestion.get('parentQuestionAnswer').value.split('|').map(ele => ' ' + ele + ' ');
    }
    const subParentAnswerValue = subquestion.get('answer').value;
    if (!subParentId) {
      if (Array.isArray(question.get('answer').value)) {
        subquestion.get('answer').setValidators(this.getValidators(subquestion.value));
        return (question.get('answer').value).includes(' ' + subquestion.get('parentQuestionAnswer').value.trim() + ' ');
      } else if (subquestion.get('parentQuestionAnswer').value === (question.get('answer').value).trim()) {
       subquestion.get('answer').setValidators(this.getValidators(subquestion.value));
        return true
      }
    }
    else {
      return !subParentId || (subParentId && question.value.subQuestionnaireAnswers.find(it => {
        return it.id == subParentId && ((Array.isArray(it.answer) ? it.answer.find((ans) => it.expectedAnswers.includes(ans)) : it.expectedAnswers.includes(it.answer))) && (((Array.isArray(it.answer)) ? (it.answer.includes(' ' + subquestion.get('parentQuestionAnswer').value + ' ')) : subquestion.get('parentQuestionAnswer').value === (it.answer).trim()) || (subquestion.get('parentQuestionAnswer').value).includes(it.answer))
      }));
    }
  }

  markFormGroupTouched(dynamicForm: FormGroup) {
    (<any>Object).values(dynamicForm.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  modifyQuestions(qa: any) {
    var possibleValues;
    if (qa.questionCode === 'state') { possibleValues = [this.states] } else { possibleValues = qa.possibleValues }
    if (qa.questionCode === 'country') { possibleValues = [this.countries] } else { possibleValues = qa.possibleValues }
    if (qa.questionCode === 'naicsDesc') { possibleValues = [this.naicsCodes] } else { possibleValues = qa.possibleValues }
    if (qa.questionCode === 'entityType') { possibleValues = [this.entityList] } else { possibleValues = qa.possibleValues }
    if (qa.questionType === 'ngselect' || 'option') {
      if (qa.possibleValues === 'NAICS_CODE') { possibleValues = [this.naicsCodes] }
      else if (qa.possibleValues === 'COUNTRY_LIST') { possibleValues = [this.countries] }
      else if (qa.possibleValues === 'STATE_LIST') { possibleValues = [this.states] }
    } else { possibleValues = qa.possibleValues }

    return possibleValues;
  }

  submitDynamicForm(type: string) {
    this.dynamicQA.value.qa = this.dynamicQA.value.qa.map((question) => {
      if (question.questionType === 'ngselect' && typeof question.answer === 'string') { question.answer = question.answer.trim() }
      if (Array.isArray(question.answer)) { question.answer = question.answer.map((e) => e.trim()) }
      if (question.subQuestionnaireAnswers.length) {
        question.subQuestionnaireAnswers = question.subQuestionnaireAnswers.map((subQuestion) => {
          if (subQuestion.questionType === 'ngselect' && typeof subQuestion.answer === 'string') { subQuestion.answer = subQuestion.answer.trim() }
          if (Array.isArray(subQuestion.answer)) { subQuestion.answer = subQuestion.answer.map((e) => e.trim()) }
          return subQuestion;
        });
      }
      return question;
    });
    if (this.dynamicQA.invalid) {
      const control = (this.dynamicQA.controls.qa) as FormArray;
      const invalidList = control.controls.filter((ele) => ele.status === 'INVALID');
      let msg = '';
      invalidList.forEach(item => {
        msg += 'Required - ' + item.value.questionCode + item.value.displayQuestion + ' </br> ';
      });
      //this.toastrService.error(msg);
      this.toastrService.error('Please enter required fields');
      this.markFormGroupTouched(this.dynamicQA);
    } else {
      if (type === 'save') {
        this.gs.otpTrue$.next(false);
        this.saveExitModal = true;
        setTimeout(() => { this.saveExitModal = false }, 200)
      } else if (type === 'continue') {
        
        this.dynamicQA.value.qa.forEach((x, i) => { if (x.subQuestionnaireAnswers.length > 0) { return x.subQuestionnaireAnswers.sort((a, b) => a.position - b.position); } })
        this.answers.emit({ route: this.nextStep, formValue: this.dynamicQA.value }
        );
      } else if (type === 'exit') {
        this.answers.emit({ route: this.currentStep, formValue: this.dynamicQA.value });
      }

    }
  }

  saveandexit($event) {
    if ($event) { this.submitDynamicForm('exit') }
  }
  prevPage() {
    this.answers.emit({ route: this.prevStep });
  }

  handleAddressChanges(place: any, subCategory: any) {
    const address = this.getAddressFromautocomplete(place.address_components);


    let questionsToPatch = this.questionandAnswers[0].filter((questions) => questions.subCategory === subCategory);
    let questionCodes = ['', '', '', '', '', '', '', ''];
    let isOptional = questionsToPatch.some((question) => question.displayQuestion === 'Mailing Address Line 1 (Optional)')
    if (address.country !== 'United States') {
      this.hideUsQuestions(subCategory, isOptional);
    } else {
      this.hideNonUsQuestions(subCategory, isOptional);
    }


    questionCodes = questionCodes.map((questionCode, index) => {
      if (index === 0) {
        return questionsToPatch.find((q) => q.displayQuestion === 'Primary Physical Address Line 1' || q.displayQuestion === 'Mailing Address Line 1 (Optional)' || q.displayQuestion === 'Mailing Address Line 1').questionCode;
      }
      else if (index === 1) {
        return questionsToPatch.find((q) => q.displayQuestion === 'Primary Physical Address Line 2 (Optional)' || q.displayQuestion === 'Mailing Address Line 2 (Optional)').questionCode;
      }
      else if (index === 2) {
        return questionsToPatch.find((q) => q.displayQuestion === 'City').questionCode;
      }
      else if (index === 3) {
        return questionsToPatch.find((q) => q.displayQuestion === 'Country').questionCode;
      }
      else if (index === 4) {
        return questionsToPatch.find((q) => q.displayQuestion === 'State').questionCode;
      }
      else if (index === 5) {
        return questionsToPatch.find((q) => q.displayQuestion.toLowerCase() === 'zip code')?.questionCode;
      }
      else if (index === 6) {
        return questionsToPatch.find((q) => q.displayQuestion === 'State/Province/Territory').questionCode;
      }
      else if (index === 7) {
        return questionsToPatch.find((q) => q.displayQuestion === 'Postal Code').questionCode;
      }
    });

    let indexes = [];
    questionCodes.forEach((qc) => {
      indexes.push((this.dynamicQA.get('qa') as FormArray).controls.findIndex((control) => control.value.questionCode === qc));
    });
    indexes.forEach((i, index) => {
      if (i > -1) {
        if (index === 0) {
          (this.dynamicQA.get('qa') as FormArray).controls[i].get('answer').patchValue(address.streetAddr);
        }
        else if (index === 1) {
          (this.dynamicQA.get('qa') as FormArray).controls[i].get('answer').patchValue('');
        }
        else if (index === 2) {
          (this.dynamicQA.get('qa') as FormArray).controls[i].get('answer').patchValue(address.city);
        }
        else if (index === 3) {
          (this.dynamicQA.get('qa') as FormArray).controls[i].get('answer').patchValue(address.country);
        }
        else if (index === 4) {
          (this.dynamicQA.get('qa') as FormArray).controls[i].get('answer').patchValue(address.state);
        }
        else if (index === 5) {
          if(address.country === 'United States') {
            (this.dynamicQA.get('qa') as FormArray).controls[i].get('answer').patchValue(address.zipCode);
          } else {
            (this.dynamicQA.get('qa') as FormArray).controls[i].get('answer').patchValue('');
          }
        }
        else if (index === 6) {
          (this.dynamicQA.get('qa') as FormArray).controls[i].get('answer').patchValue(address.provinces);
        }
        else if (index === 7) {
          if(address.country === 'United States') {
            (this.dynamicQA.get('qa') as FormArray).controls[i].get('answer').patchValue('');
          } else {
            (this.dynamicQA.get('qa') as FormArray).controls[i].get('answer').patchValue(address.postalCode);
          }
        }
      }
    });
  }

  handleSubQuestionAddressChange(place: any, question: any) {
    const address = this.getAddressFromautocomplete(place.address_components);

    if (address.country !== 'United States') {
      this.hideUsSubQuestions(question);
    } else {
      this.hideNonUsSubQuestions(question);
    }


    let indexOfParentQuestion = (this.dynamicQA.get('qa') as FormArray).controls.findIndex((control) => control.value.questionCode === question.value.questionCode);

    ((this.dynamicQA.get('qa') as FormArray).controls[indexOfParentQuestion].get('subQuestionnaireAnswers') as FormArray).controls.forEach((control) => {
      if (control.get('displayQuestion').value === 'Mailing Address Line 1') {
        control.get('answer').patchValue(address.streetAddr);
      }
      else if (control.get('displayQuestion').value === 'Mailing Address Line 2(Optional)') {
        control.get('answer').patchValue('');
      }
      else if (control.get('displayQuestion').value === 'City') {
        control.get('answer').patchValue(address.city);
      }
      else if (control.get('displayQuestion').value === 'State/Province/Territory') {
        control.get('answer').patchValue(address.provinces);
      }
      else if (control.get('displayQuestion').value === 'State') {
        control.get('answer').patchValue(address.state);
      }
      else if (control.get('displayQuestion').value === 'Postal Code') {
        control.get('answer').patchValue(address.postalCode);
      }
      else if (control.get('displayQuestion').value === 'Zip Code') {
        control.get('answer').patchValue(address.zipCode);
      }
      else if (control.get('displayQuestion').value === 'Country') {
        control.get('answer').patchValue(address.country);
      }
    });
  }

  getAddressFromautocomplete(addressComponent: any[]): any {
    let address: any = null;
    if (addressComponent && addressComponent.length) {
      address = {};
      let streetName = '';
      for (let i = 0; i < addressComponent.length; i++) {
        const currentComponet = addressComponent[i];
        const types = currentComponet.types;
        if (!types || types.legth === 0) {
          continue;
        }
        switch (types[0]) {
          case 'route':
            streetName += ' ' + currentComponet['long_name'];
            break;
          case 'street_number':
            if (streetName.length > 0) {
              streetName = currentComponet['long_name'] + '' + streetName;
            } else {
              streetName += currentComponet['long_name'] + '';
            }
            break;
          case 'locality':
            address.city = currentComponet['long_name'] || '-';
            break;
          case 'postal_town':
            address.city = currentComponet['long_name'] || '-';
            break;
          case 'administrative_area_level_1':
            address.state = currentComponet['long_name'];
            address.provinces = currentComponet['long_name'];
            break;
          case 'country':
            address.country = currentComponet['long_name'];
            address.countryAlpha2 = currentComponet['short_name'];
            break;
          case 'postal_code':
            address.zipCode = currentComponet['short_name'];
            address.postalCode = currentComponet['short_name'];
            break;
        }
      }
      if (streetName.trim().length > 0) {
        address.streetAddr = streetName;
      } else {
        address.streetAddr = '';
      }
    }
    return address;
  }

  onPasteEvent(event: ClipboardEvent) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/\p{Emoji}/u.test(pastedText)) {
      return false;
    }
    return true;
  }

  getValidators(question: any): any {
    if(question.questionCode === 'SQC-100040-1') {
      question = {
        ...question,
        maxLength: 40
      };
    }
    const arrVals = [];
    if(question.displayable) {
      if (question['mandatory']) {
        arrVals.push(Validators.required)
      }
      if (question['alphaNumOnly']) {
        arrVals.push(Validators.pattern('^[a-zA-Z0-9 ]*$'));
      }
      if (question['isName']) {
        arrVals.push(Validators.pattern('^[a-zA-Z0-9- ]*$'));
      }
      if (question['taxLiability']) {
        arrVals.push(Validators.pattern('^[0-9?]*\\.?[0-9?]*$'), Validators.max(100));
      }
      if (question?.maxLength) {
        arrVals.push(Validators.maxLength(question['maxLength']));
      }
      if (question?.minLength) {
        arrVals.push(Validators.minLength(question['minLength']));
      }
      if (question['maxValue']) {
        arrVals.push(Validators.max(question['maxValue']));
      }
      if (question['minValue']) {
        arrVals.push(Validators.min(question['minValue']));
      }
      //webaddress field check and adding validators as required
      const isWebAddress = JSON.parse(question?.extAdditionalSpec)?.isWebAddress;
      if(isWebAddress){
        arrVals.push(Validators.pattern(/^(?=.*\.)[a-zA-Z0-9:=/.\-?]+$(?<!\.)/));
      }
      const type = JSON.parse(question?.additionalSpec).type;
      if (type === 'email') {
        arrVals.push(Validators.pattern('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$'));
      }
      if (type === 'phoneNbr') {
        arrVals.push(Validators.pattern('(?!000)[0-9][0-9]{2}[0-9]{3}[0-9]{4}'));
      }
      if (type === 'residentAlienCard') {
        arrVals.push(Validators.pattern('^[0-9a-zA-Z]*[0-9][0-9a-zA-Z]*$'));
      }
      if (type === 'passportNumber') {
        arrVals.push(Validators.pattern('^[0-9a-zA-Z]*[0-9][0-9a-zA-Z]*$'));
      }
      if (type === 'postalCode') {
        arrVals.push(Validators.maxLength(6));
      }
      if (type === 'exemptionCode') {
        arrVals.push(Validators.pattern('(0?[1-9]|1[0-9]|2[0-5])'));
      }
    }


    return arrVals;
  }
  patchId(event: any): void {
    this.dynamicForm.patchValue({
      customerIdentity: event
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next({});
    this.destroy$.complete();
  }

  returnArray(item) {
    var array = [];
    array.push(item);
    return array;
  }
}
