export const environment  = {
  production: false,
  isLocal: false,
  oktaIssuer: 'https://tcb.okta.com/oauth2/default',
  apiUrl: '/prod/initio-prod/gateway-service/',
  unityUrl:'https://unityapi.texascapitalbank.com/',
  adminAPI:'http://localhost:8080',
  coreServiceUrl:"commercial-core/",
  oktaClientId: '0oauu551smFPOCGYW0x7',
  oktaLocal: false,
};
