import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { AppFacade } from '@app/core';
import {combineLatest, distinctUntilChanged, Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { ProfileCreationStore } from '@app/views/profile-creation/profile-creation.store';

@Component({
  selector: 'app-layer-entities',
  templateUrl: './layer-entities.component.html',
  styleUrls: ['./layer-entities.component.scss'],
})
export class LayerEntitiesComponent implements OnInit, OnDestroy {
  @Output() output = new EventEmitter();
  isModalOpen = false;
  tableHeaders = [
    { key: 'name', value: 'NAME' },
    { key: 'entityType', value: 'ENTITY TYPE' },
    { key: 'existing', value: 'EXISTING ENTITY' },
    { key: 'cif', value: 'CF NUMBER' },
    { key: 'actions', value: 'ACTIONS' },
  ];
  entityList: any[];
  layerEntityFormGroup = new FormGroup({
    entityName: new FormControl(null),
    entityType: new FormControl( null),
    existingEntity: new FormControl(null),
    cifNumber: new FormControl(null),
    layerNumber: new FormControl(null),
    id: new FormControl(null)
  });
  layers: any[] = [];
  destroy$ = new Subject();

  constructor(private appFacade: AppFacade, private profileCreationStore: ProfileCreationStore) {}

  ngOnInit() {
    combineLatest([this.appFacade.entityList$, this.profileCreationStore.preApplicantBusinessProfile$])
      .pipe(distinctUntilChanged((prev, next) => isEqual(prev, next)), takeUntil(this.destroy$))
      .subscribe(([entityList, preApplicantBusinessProfile]) => {
        if(entityList?.length) {
          this.entityList = entityList?.map((entityType) => entityType.itemName);
          this.getValidatorsForLayerEntities();

          // @ts-ignore
          this.layers = preApplicantBusinessProfile?.layerEntities?.map((layer) => {
            let formattedLayer = { };
            if(layer?.existingEntity) {
              formattedLayer = {
                ...layer,
                existingEntity: 'Yes',
              };
            }
            else {
              formattedLayer = {
                ...layer,
                existingEntity: 'No',
              };
            }
            return formattedLayer;
          });
          this.layers = this.layers.sort((a, b) => a.layerNumber - b.layerNumber);
        }
      });
  }

  addNew() {
    this.isModalOpen = true;
  }

  onWillDismiss() {
    this.isModalOpen = false;
    this.layerEntityFormGroup.reset();
  }

  save()  {
    if(this.layerEntityFormGroup.invalid) {
      this.markFormGroupTouched(this.layerEntityFormGroup);
    } else {
      if(this.layerEntityFormGroup.value.layerNumber !== null) {
        this.layers[this.layerEntityFormGroup.value.layerNumber] = {
          ...this.layerEntityFormGroup.value
        };

      } else {
        const layerNumberCalculated = this.layers.length;
        this.layers = [
          ...this.layers,
          {
            entityName: this.layerEntityFormGroup.value.entityName,
            entityType: this.layerEntityFormGroup.value.entityType,
            existingEntity: this.layerEntityFormGroup.value.existingEntity,
            cifNumber: this.layerEntityFormGroup.value.cifNumber,
            layerNumber: layerNumberCalculated,
          }
        ];
      }
      this.isModalOpen = false;
      this.layerEntityFormGroup.reset();
      this.output.emit({
         layers: this.layers
      });
    }
  }

  markFormGroupTouched(dynamicForm: FormGroup) {
    (<any>Object).values(dynamicForm.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  deleteLayer(index: number) {
    this.layers.splice(index, 1);

    this.layers = this.layers.map((layer, i: number) => ({
        ...layer,
        layerNumber: i
    }));

    this.output.emit({
      layers: this.layers
    });
  }

  editLayer(index: number) {
    this.layerEntityFormGroup.patchValue({
      entityName: this.layers[index].entityName,
      entityType: this.layers[index].entityType,
      existingEntity: this.layers[index].existingEntity,
      cifNumber: this.layers[index].cifNumber,
      layerNumber: this.layers[index].layerNumber ?? index,
      id: this.layers[index].id
    });
    this.isModalOpen = true;
  }

  getValidatorsForLayerEntities() {
    this.layerEntityFormGroup.controls.entityName.setValidators([Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]);
    this.layerEntityFormGroup.controls.entityType.setValidators([Validators.required]);
    this.layerEntityFormGroup.controls.existingEntity.setValidators([Validators.required]);
    this.layerEntityFormGroup.controls.cifNumber.setValidators([Validators.maxLength(10), Validators.minLength(10)]);
  }

  ngOnDestroy(): void {
    this.destroy$.next({});
    this.destroy$.complete();
  }
}
