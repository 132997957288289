import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandlerInterceptor } from "@app/core";
import { TokenInterceptor } from '@app/core';
//Okta Config
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '@env/environment';

//Service
import { SampleServiceService } from '@app/core';
import { AuthService } from '@app/core';
import { AuthGuardService } from '@app/core';
import { GlobalService } from '@app/core';

const oktaConfig = {
  issuer: environment.oktaIssuer,
  clientId: environment.oktaClientId,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'offline_access', 'profile', 'commercial_internal'],
};


@NgModule({
  declarations: [],
  providers: [SampleServiceService, GlobalService, AuthService, AuthGuardService, {
    provide: OKTA_CONFIG,
    // useValue: oktaAuth,
    useFactory: () => {
      const oktaAuth = new OktaAuth(oktaConfig);
      return { oktaAuth };
    },
  },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  imports: [
    CommonModule,
    OktaAuthModule
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error("Module has already been created");
    }
  }
}
