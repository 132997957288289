
import {Component, Input, OnInit, Output, EventEmitter, OnChanges} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppFacade, GlobalService } from '@app/core';
import { ToastrService } from '@app/core/services/toastr.service';
import { combineLatest } from 'rxjs';
import { ApplicationFlow, highRiskIndustriesValues } from '@app/models/application-flow';
import { ApplicationStore } from '@app/views/application/application.page.store';
import {ProfileCreationStore} from "@app/views/profile-creation/profile-creation.store";
import { getApartmentIdentifierValue } from '@app/models/apartmentnumberIdentifiers';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dynamic-form-preapplication',
  templateUrl: './dynamic-form-preapplication.component.html',
  styleUrls: ['./dynamic-form-preapplication.component.scss'],
})
export class DynamicFormPreapplicationComponent implements OnInit, OnChanges {
  @Input() questions: any;
  @Input() responseAnswers: any;
  @Input() subCatagories: string[];
  @Input() nextStep: string;
  @Input() prevStep: string;
  @Input() type: string;
  @Input() trigger;
  @Input() questionSection: string;
  @Input() currentStep: number;
  @Input() currentIndex: number;
  @Input() currentView;
  @Input() isModal;
  @Input() isEstate = false;
  @Output() answers = new EventEmitter();
  detectScroll$ = this.globalService.detectScroll$;
  saveExitModal: boolean = false;
  isShowTypeahead = false;
  usPerson;
  countries;
  maxDate;
  dataLoaded = false;
  appStatus: any;
  coApps$ = this.applicationStore.coapplicants$;
  existingCustomer: boolean = false;
  existingCustomerView!: boolean;
  existingCustomerValue;
  ApplicationFlow = ApplicationFlow;
  layers: any[] = [];
  dynamicForm: any = new FormGroup({});
  isModalOpen = false;
  modalQuestion: any = {};
  results: any[] = [];
  estateData: any = {};
  makeEstateCall: boolean = true;
  constructor(private fb: FormBuilder, private appFacade: AppFacade, private globalService: GlobalService, private toastrService: ToastrService, private applicationStore: ApplicationStore, private profileCreationStore: ProfileCreationStore,private _snackBar: MatSnackBar) {}

  ngOnInit() {
    combineLatest([
      this.appFacade.states$,
      this.appFacade.countries$,
      this.appFacade.naicsCodes$,
      this.appFacade.entityList$,
      this.appFacade.getBsaProfile$,
      this.profileCreationStore.preApplicantBusinessProfile$,
      this.applicationStore.profileInformation$,
      this.applicationStore.estateData$
    ]).subscribe(([states, countries, naicsCodes, entityList, getBsaProfile, preApplicantBusinessProfile, profileInformation, estateData]) => {
      this.existingCustomerView = getBsaProfile?.organization?.existingCustomer;
      this.questions = this.modifyQuestions(this.questions, states, countries, naicsCodes, entityList);
      this.maxDate = new Date();
      window.scroll(0, 0)
      if (this.maxDate) {
        var year = this.maxDate.getFullYear();
        var month = this.maxDate.getMonth() + 1;
        var dt = this.maxDate.getDate();
        dt = dt - 1;
        if (dt < 10) {
          dt = '0' + dt
        }
        if (month < 10) {
          month = '0' + month
        }
        this.maxDate = year + '-' + month + '-' + dt;
      }
      // @ts-ignore
      this.layers = preApplicantBusinessProfile?.layerEntities;
      this.appStatus = getBsaProfile.applicationStatus;
      if(profileInformation?.organization?.entityType === 'Estate' && !this.estateData?.estateCustomerInfo && this.makeEstateCall) {
        this.applicationStore.getEstateData(profileInformation.applicationId);
        this.makeEstateCall = false;
      }
      this.estateData = estateData;
    });
    //this.dataLoaded = true;
    if (this.dynamicForm.get('taxIdFlag')?.value) {
      this.dynamicForm.get('taxIdFlag').valueChanges.subscribe((res) => {
        this.questions.filter((ele: any) => {
          if (ele.questionCode === 'taxId' && (res === ' Social Security ' || res === 'Social Security')) {
            ele.mask = '000-00-0000'
          }
          if (ele.questionCode === 'taxId' && (res === ' Employer tax id ' || res === 'Employer tax id')) {
            ele.mask = '00-0000000'
          }
        })
      })
    }
  }


  ngOnChanges(changes: any) {
    this.createGroup(changes.questions?.currentValue, changes.responseAnswers?.currentValue);
  }

  createGroup(data: any, answers: any) {
    if (this.type === 'edit') {
      this.existingCustomer = true;
      this.existingCustomerValue = answers;
    }
    data?.forEach((element, i) => {
      this.dynamicForm.addControl(element.questionCode, new FormControl({
        disabled: element.disabled,
        value: ''
      }, [Validators.maxLength(element.maxLength)]), element.questionType);
      this.dynamicForm.get(element.questionCode).setValidators(this.getValidators(element));
      if (element.subQuestionnaires) {
        element.subQuestionnaires.forEach((subQuestions) => {
          this.dynamicForm.addControl(subQuestions.questionCode, new FormControl({
            disabled: subQuestions.disabled,
            value: ''
          }, [Validators.maxLength(subQuestions.maxLength)]), subQuestions.questionType);
          this.dynamicForm.get(subQuestions.questionCode).setValidators(this.getValidators(subQuestions));
          if (subQuestions.subQuestionnaires) {
            subQuestions.subQuestionnaires.forEach((secondSubQuestions) => {
              this.dynamicForm.addControl(secondSubQuestions.questionCode, new FormControl({
                disabled: secondSubQuestions.disabled,
                value: ''
              }, [Validators.maxLength(secondSubQuestions.maxLength)]), secondSubQuestions.questionType);
              this.dynamicForm.get(secondSubQuestions.questionCode).setValidators(this.getValidators(secondSubQuestions));
            });
          }
        });
      }
    });

    this.dynamicForm.patchValue(answers);
    if (answers?.businessExceptionCategory || answers?.businessProhibitedCategory) {
      this.dynamicForm.patchValue({
        businessExceptionCategory: answers.businessExceptionCategory.split('|').map((expectedAnswer) => ' ' + expectedAnswer + ' '),
        businessProhibitedCategory: answers.businessProhibitedCategory.split('|').map((expectedAnswer) => ' ' + expectedAnswer + ' '),
      });
    }
    if (answers?.address?.city) {
      this.dynamicForm.patchValue({
        city: answers?.address?.city
      });
    }
    if (answers?.address?.apartmentNumberId) {
      this.dynamicForm.patchValue({
        apartmentNumberId: getApartmentIdentifierValue(answers?.address?.apartmentNumberId)
      });
    }
    if (answers?.usPerson) {
      if (answers?.usPerson === true) {
        this.dynamicForm.patchValue({
          usPerson: 'Yes'
        });
      } else if (answers?.usPerson === false) {
        this.dynamicForm.patchValue({
          usPerson: 'No'
        });
      }
    } else {
      this.dynamicForm.patchValue({
        usPerson: null
      });
    }
    if (data?.find((question) => question.questionCode === 'businessPhoneNbr')) {
      this.dynamicForm.patchValue({
        businessPhoneNbr: answers?.address?.businessPhoneNbr
      });
    }
    if (answers?.entityType?.trim() === 'Limited Liability Company (LLC)') {
      this.dynamicForm.patchValue({
        soleMember: answers.llcDTOS[0].soleMember === true ? 'Yes' : 'No',
        disregardedEntity: answers.llcDTOS[0].disregardedEntity === true ? 'Yes' : 'No',
        llcName: answers.llcDTOS[0].llcName,
        llcTin: answers.llcDTOS[0].llcTin,
        llcTaxIdFlag: answers.llcDTOS[0].llcTaxIdFlag
      });
    }

    return this.dynamicForm;
  }

  changeBoolean() {
    this.dataLoaded = true;
  }

  openModal(question: any){
    this.isModalOpen = true;
    this.modalQuestion = question;
    this.results = question.possibleValues;
  }

  handleClear() {
    this.results = this.modalQuestion.possibleValues;
  }

  setNaicsCode(resultDesc: any) {
    this.dynamicForm.patchValue({
      naicsDesc: resultDesc
    });
    this.clearValuesForModal();
  }

  clearValuesForModal() {
    this.isModalOpen = false;
    this.modalQuestion = {};
    this.results = [];
  }

  handleSearchChange(event) {
    const term = event.target.value.toLowerCase();
    this.results = this.modalQuestion.possibleValues.filter((d) => {
      let include = false;
      if((d?.subTitle && d?.subTitle.toLowerCase().includes(term)) || (d?.subCode && d?.subCode.toLowerCase().includes(term))) {
        include = true;
      }
      return include;
    });
  }

  filterQuestions(questions: any[], subCategory): any[] {
    const val = questions?.filter((question) => question);
    return val;
  }

  determineDisplaySubQuestion(question: any): boolean {
    let displaySubQuestion: boolean = false;
    if (question.expectedAnswers) {
      let answer = this.dynamicForm.value[question.questionCode];
      displaySubQuestion = question.expectedAnswers.includes(answer);
    }
    return displaySubQuestion;
  }

  markFormGroupTouched(dynamicForm: FormGroup) {
    (<any>Object).values(dynamicForm.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  modifyQuestions(questions: any[], states: any[], countries: any[], naicsCodes: any[], entityList: any[]): any[] {
    var mappingQuestions = [];
    questions.map((question: any) => {
      if (question?.extAdditionalSpec) {
        var dropdownSettings = (JSON.parse(question?.extAdditionalSpec))?.dropdownSettings?.singleSelection;
        var gridVal = (JSON.parse(question?.extAdditionalSpec))?.gridSize;
        var elementPosition = (JSON.parse(question?.extAdditionalSpec))?.position;
        var qa = {
          ...question,
          dropdownSettings: {singleSelection: dropdownSettings},
          width: gridVal,
          position: elementPosition,
        }
      } else {
        var qa = {
          ...question,
        }
      }

      if (qa.questionCode === 'state') {
        qa.possibleValues = states;
      }
      if (qa.questionCode === 'country') {
        qa.possibleValues = countries;
      }
      if (qa.questionCode === 'naicsDesc') {
        qa.possibleValues = naicsCodes;
      }
      if (qa.questionCode === 'entityType') {
        qa.possibleValues = entityList;
      }
      if (qa.questionType === 'ngselect' || 'option') {
        if (qa.possibleValues === 'NAICS_CODE') {
          qa.possibleValues = naicsCodes;
        } else if (qa.possibleValues === 'COUNTRY_LIST') {
          qa.possibleValues = countries;
        } else if (qa.possibleValues === 'STATE_LIST') {
          qa.possibleValues = states;
        }
      }

      if (qa.subQuestionnaires) {
        qa.subQuestionnaires = qa.subQuestionnaires.map((subQ) => {
          if (subQ.questionType === 'ngselect' || 'option') {
            if (subQ.possibleValues === 'NAICS_CODE') {
              subQ.possibleValues = naicsCodes;
            } else if (subQ.possibleValues === 'COUNTRY_LIST') {
              subQ.possibleValues = countries;
            } else if (subQ.possibleValues === 'STATE_LIST') {
              subQ.possibleValues = states;
            } else if (subQ.possibleValues === 'COUNTRY_LIST_NO_US') {
              let countriesList = [...countries]
              let index = countriesList.findIndex((each: any) => each['name'] === 'United States');
              if (index > -1) {
                countriesList.splice(index, 1)
              }
              subQ.possibleValues = countriesList;
            }
          }
          return subQ;
        })
      }

      mappingQuestions.push(qa);
    });
    return mappingQuestions;
  }

  submitDynamicForm(shouldContinue) {
    var dynamicFormValue: any = {...this.dynamicForm.value};
    if (Array.isArray(dynamicFormValue.businessExceptionCategory) || Array.isArray(dynamicFormValue.businessProhibitedCategory)) {

      if(dynamicFormValue.businessExceptionCategory?.length === 1) {
        dynamicFormValue.businessExceptionCategory = dynamicFormValue.businessExceptionCategory?.toString()?.trim();
      } else if(dynamicFormValue.businessExceptionCategory?.length > 1) {
        let tempExceptionCat = '';

        dynamicFormValue.businessExceptionCategory?.forEach((cat: string, index: number) => {
            tempExceptionCat += cat?.trim();

            if(index !== dynamicFormValue.businessExceptionCategory?.length - 1) {
              tempExceptionCat += '|';
            }
        });
        
        dynamicFormValue.businessExceptionCategory = tempExceptionCat;
      }

      if(dynamicFormValue.businessProhibitedCategory?.length === 1) {
        dynamicFormValue.businessProhibitedCategory = dynamicFormValue.businessProhibitedCategory.toString()?.trim();
      } else if(dynamicFormValue.businessProhibitedCategory?.length > 1) {
        let tempProhibitCat = '';

        dynamicFormValue.businessProhibitedCategory?.forEach((cat: string, index: number) => {
          tempProhibitCat += cat?.trim();

            if(index !== dynamicFormValue.businessProhibitedCategory?.length - 1) {
              tempProhibitCat += '|';
            }
        });
        
        dynamicFormValue.businessProhibitedCategory = tempProhibitCat;
      }
    }
    if (dynamicFormValue?.entityType) {
      dynamicFormValue.entityType = dynamicFormValue?.entityType?.trim();
    }

    if (dynamicFormValue?.llcTaxIdFlag) {
      dynamicFormValue.llcTaxIdFlag = dynamicFormValue?.llcTaxIdFlag?.trim();
    }
     if (this.dynamicForm.invalid) {
      this.toastrService.error('Please enter required fields');
      this.markFormGroupTouched(this.dynamicForm);
    } else if (shouldContinue === 'continue' && this.dynamicForm.valid) {
      if (this.existingCustomer) {
        dynamicFormValue = {
          ...dynamicFormValue,
          id: this.existingCustomerValue.id,
          customerId: this.existingCustomerValue.customerId,
          authorizedSigner: this.existingCustomerValue.authorizedSigner,
          beneficialOwner: this.existingCustomerValue.beneficialOwner,
          controlPerson: this.existingCustomerValue.controlPerson,
          trust: this.existingCustomerValue.trust,
          primaryApplicant: this.existingCustomerValue.primaryApplicant,
          modifiedDate: new Date(),
        };
      } else {
        dynamicFormValue = {
          ...dynamicFormValue,
          customerId: Math.floor(1000 + Math.random() * 9000) + 'NEW',
          modifiedDate: new Date(),
        };
      }

      if(this.nextStep === 'success') {
        dynamicFormValue.layerEntities = this.layers;
      }
      this.existingCustomer = false;
      this.answers.emit({route: this.nextStep, formValue: dynamicFormValue});
    }
    if (shouldContinue === 'save') {
      this.saveExitModal = true;
      setTimeout(() => {
        this.saveExitModal = false
      }, 200)
    } else if (shouldContinue === 'exit') {
      this.answers.emit({route: this.currentStep, formValue: dynamicFormValue});
    }
  }

  closeModalScreen() {
    this.answers.emit(
      {
        route: this.prevStep,
      }
    );
  }

  handleLayerEntities(event: any) {
    this.layers = event.layers.map((layer: any) => ({
      ...layer,
      entityType: layer.entityType.trim(),
      existingEntity: layer.existingEntity.trim() === 'Yes'
    }));
  }

  getDateValidity(condition: string) {
    switch (condition) {
      case 'today':
        return this.formatDate(new Date());
        break;
      case 'tomorrow':
        return this.formatDate(new Date(new Date().setDate(new Date().getDate() + 1)));
        break;
      case '18yearsago':
        return this.formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 18)));
        break;
      case 'start1800':
        return this.formatDate(new Date(1800, 0, 1));
        break;
      case 'start120':
        return this.formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 120)));
        break;
    }
    return null;
  }

  formatDate(dateToFormat) {
    if (dateToFormat) {
      var year = dateToFormat.getFullYear();
      var month = dateToFormat.getMonth() + 1;
      var dt = dateToFormat.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
      return year + '-' + month + '-' + dt;
    }
  }

  saveandexit($event) {
    if ($event) {
      this.submitDynamicForm('exit')
    }
  }

  prevPage() {
    this.answers.emit({route: this.prevStep});
  }

  handleAddressChanges(place: any, questionCode: any) {
    const address = this.getAddressFromautocomplete(place.address_components);
    this.dynamicForm.patchValue({
      address1: address.streetAddr,
      address2: '',
      city: address.city,
      country: address.country,
      state: address.state,
      zipCode: address.zipCode,
      provinces: address.provinces,
      postalCode: address.postalCode,
    });
    let usQuestions = ['zipCode', 'state'];
    let nonUsQuestions = ['postalCode', 'provinces'];
    if (address.country == 'United States') {
      this.hideQuestions(nonUsQuestions);
      this.unHideQuestions(usQuestions);
    } else {
      this.hideQuestions(usQuestions);
      this.unHideQuestions(nonUsQuestions);
    };
    combineLatest([this.appFacade.states$, this.appFacade.countries$, this.appFacade.naicsCodes$, this.appFacade.entityList$, this.appFacade.getBsaProfile$, this.profileCreationStore.preApplicantBusinessProfile$])
      .subscribe(([states, countries, naicsCodes, entityList, getBsaProfile, preApplicantBusinessProfile]) => {
        this.questions = this.modifyQuestions(this.questions, states, countries, naicsCodes, entityList);
      });
  }

  hideQuestions(hideQuestions : any){
    hideQuestions.forEach(questionCode =>{
      let index = this.questions.findIndex(
        (each: any) => each['questionCode'] == questionCode);
      if (index > -1) {
        this.questions[index].displayable =false;
        this.questions[index].mandatory =false;
      }
    })
  }
  unHideQuestions(unHideQuestions : any){
    unHideQuestions.forEach(questionCode =>{
      let index = this.questions.findIndex(
        (each: any) => each['questionCode'] == questionCode);
      if (index > -1) {
        this.questions[index].displayable =true;
        this.questions[index].mandatory =true;
      }
    })
  }

  getSubQuestions(question) {
    return question.subQuestionnaires;
  }

  handleChange(event, question) {
    this.isShowTypeahead = true;
    const query = event.target.value.toLowerCase();
    this.coApps$.subscribe((res: any[]) => {
      res = res.filter((each) => (!each.existingCustomer) || (each.existingCustomer && each.primaryApplicant));

      const name = res.map((coApp) => coApp.firstName + ' ' + coApp.lastName);

      res = res.map((coAppliant: any) => {
        if(this.estateData?.estateCustomerInfo?.some((estateCustomer: any) => estateCustomer.firstName + ' ' + estateCustomer.lastName === coAppliant.firstName + ' ' + coAppliant.lastName)) {
          coAppliant = {
            ...coAppliant,
            profileType: this.estateData.estateCustomerInfo[0]?.profileType
          };
        }
        return coAppliant;
      });

      if(this.estateData?.estateCustomerInfo) {
        question.possibleValues = [
          ...res,
          ...this.estateData?.estateCustomerInfo?.filter((estateCustomer) => {
            return !name.includes(estateCustomer.firstName + ' ' + estateCustomer.lastName);
          }),
        ];
      } else {
        question.possibleValues = [
          ...res,
        ];
      }
    });

    if (!query) {
      question.possibleValues = [];
    } else {
      question.possibleValues = question.possibleValues.filter(d => d.firstName.toLowerCase().indexOf(query) > -1);
    }
  }

  typeaheadSelect(result, question) {
    this.isShowTypeahead = false;

    const customerIdentity = [...result.customerIdentity].map(({id, ...keepAttrs}) => keepAttrs);
    const address = {...result.address};
    if(this.isEstate) {
      if(address.id) {
        delete address.id;
      }
    }
    let newResult = {...result, ...address, address: address, customerIdentity: customerIdentity};

    this.existingCustomer = true;
    this.existingCustomerValue = newResult;
    this.createGroup(this.questions, newResult);
    question.possibleValues = [];
  }

  getAddressFromautocomplete(addressComponent: any[]): any {
    let address: any = null;
    if (addressComponent && addressComponent.length) {
      address = {};
      let streetName = '';
      for (let i = 0; i < addressComponent.length; i++) {
        const currentComponet = addressComponent[i];
        const types = currentComponet.types;
        if (!types || types.legth === 0) {
          continue;
        }
        switch (types[0]) {
          case 'route':
            streetName += ' ' + currentComponet['long_name'];
            break;
          case 'street_number':
            if (streetName.length > 0) {
              streetName = currentComponet['long_name'] + '' + streetName;
            } else {
              streetName += currentComponet['long_name'] + '';
            }
            break;
          case 'locality':
            address.city = currentComponet['long_name'] || '-';
            break;
          case 'postal_town':
            address.city = currentComponet['long_name'] || '-';
            break;
          case 'administrative_area_level_1':
            address.state = currentComponet['long_name'];
            address.provinces = currentComponet['long_name'];

            break;
          case 'country':
            address.country = currentComponet['long_name'];
            address.countryAlpha2 = currentComponet['short_name'];
            break;
          case 'postal_code':
            address.zipCode = currentComponet['short_name'];
            address.postalCode = currentComponet['short_name'];
            break;
        }
      }
      if (streetName.trim().length > 0) {
        address.streetAddr = streetName;
      } else {
        address.streetAddr = '';
      }
    }
    return address;
  }

  onPasteEvent(event: ClipboardEvent) {
    let clipboardData = event.clipboardData || (<any>window).clipboardData;
    let pastedText = clipboardData.getData('text');
    if (/\p{Emoji}/u.test(pastedText)) {
      return false;
    }
    return true;
  }

  getValidators(question: any): any {
    const arrVals = [];

    if (question['mandatory']) {
      arrVals.push(Validators.required)
    }
    if (question['alphaNumOnly']) {
      arrVals.push(Validators.pattern('^[a-zA-Z0-9 ]*$'));
    }
    if (question['isName']) {
      arrVals.push(Validators.pattern('^[a-zA-Z0-9- ]*$'));
    }
    if (question['taxLiability']) {
      arrVals.push(Validators.pattern('^[0-9?]*\\.?[0-9?]*$'), Validators.max(100));
    }
    if (question['isWebAddress']) {
      arrVals.push(Validators.pattern('^[a-zA-Z0-9:=/\\.-?]*$'));
    }
    if (question?.maxLength) {
      arrVals.push(Validators.maxLength(question['maxLength']));
    }
    if (question?.minLength) {
      arrVals.push(Validators.minLength(question['minLength']));
    }
    if (question['maxValue']) {
      arrVals.push(Validators.max(question['maxValue']));
    }
    if (question['minValue']) {
      arrVals.push(Validators.min(question['minValue']));
    }
    if (question.type === 'email') {
      arrVals.push(Validators.pattern('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$'));
    }
    // if (question.type === 'phoneNbr'|| question.type==='tel') {
    //   arrVals.push(Validators.pattern('(?!000)[0-9][0-9]{2}[0-9]{3}[0-9]{4}'));
    // }
    if (question.type === 'residentAlienCard') {
      arrVals.push(Validators.pattern('^[0-9a-zA-Z]*[0-9][0-9a-zA-Z]*$'));
    }
    if (question.type === 'passportNumber') {
      arrVals.push(Validators.pattern('^[0-9a-zA-Z]*[0-9][0-9a-zA-Z]*$'));
    }
    if (question.type === 'postalCode') {
      arrVals.push(Validators.pattern('^[A-z0-9 ]{6,11}$'));
    }
    if (question.type === 'exemptionCode') {
      arrVals.push(Validators.pattern('(0?[1-9]|1[0-9]|2[0-5])'));
    }

    return arrVals;
  }

  patchId(newvalue: any): void {
    this.dynamicForm.patchValue({
      customerIdentity: newvalue
    });
  }

  getMask(questionCode: string, mask: string) {
    if (questionCode === 'taxId') {
      if ((this.dynamicForm.value.taxIdFlag &&
          this.dynamicForm.value.taxIdFlag === 'Social Security') ||
        (this.dynamicForm.value.taxIdFlag && this.dynamicForm.value.taxIdFlag === 'SSN')
      ) {
        return 'XXX-XX-0000';
      } else {
        return 'XX-XXX0000';
      }
    }
    if (questionCode === 'llcTin') {
      if (
        (this.dynamicForm.value.llcTaxIdFlag &&
          this.dynamicForm.value.llcTaxIdFlag === 'Social Security') ||
        (this.dynamicForm.value.llcTaxIdFlag && this.dynamicForm.value.taxIdFlag === 'SSN')
      ) {
        return 'XXX-XX-0000';
      } else {
        return 'XX-XXX0000';
      }
    }
    if (
      this.dynamicForm.value['QC-1022'] === 'Yes' &&
      this.dynamicForm.value['SQC-10000-2'] === 'Yes' &&
      questionCode === 'SQC-10001-1'
    ) {
      if (
        this.dynamicForm.value &&
        this.dynamicForm.value['SQC-10002-1']?.includes('Social Security')
      ) {
        return '000-00-0000';
      } else {
        return '00-0000000';
      }
    }
    return mask;
  }
  optionSelected(selectedvalue: any,quesCode: string){
    let selectedValues=selectedvalue.target.value;
    let lastSelectedValue= selectedValues[selectedValues.length-1];
    if(highRiskIndustriesValues[quesCode]?.includes(lastSelectedValue?.trim())){
      this._snackBar.open(
        'The answer(s) provided to specific question(s) for this application indicate Enhanced Due Diligence is required. Prior to submission please contact the EDD Team: edd@texascapitalbank.com ',
        'OK',
        { verticalPosition: 'top', panelClass: 'custom-edd-banner' }
      );
    }
  }
  
}
