import { Inject, Injectable } from "@angular/core";
import { getUserInfo, OktaAuth } from "@okta/okta-auth-js";
import { BehaviorSubject, from } from "rxjs";
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';

@Injectable()
export class AuthService {
  public userInfo = new BehaviorSubject('');
  UserData = this.userInfo.asObservable();

  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth,private router:Router) { }
  
  async isLoggedIn(): Promise<boolean> {
    return await this.oktaAuth.isAuthenticated();
  }

  getTokenObj(): any {
    const tokenString = localStorage.getItem('okta-token-storage');
    const tokenObj = tokenString ? JSON.parse(tokenString) : {};
    return tokenObj;
  }

  getAccessToken(): string | undefined {
    return this.getTokenObj()?.accessToken?.accessToken;
  }

  getUserInfo() {
    return from(this.oktaAuth.getUser());
  }

  getGroups(): string[] {
    return this.getTokenObj()?.accessToken?.claims?.groups;
  }

  getSamAccountName(): string {
    return this.getTokenObj()?.accessToken?.claims?.samAccountName;
  }

  getEmail(): string {
    return this.getTokenObj()?.accessToken?.claims?.sub;
  }

  signOut() {
    this.oktaAuth.signOut()
  }

}