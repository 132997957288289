import { createFeatureSelector, createSelector } from '@ngrx/store';
import { APP_STATE, IApp } from './app.reducer';
export const appState = createFeatureSelector<IApp>(APP_STATE);


//export const getSampleTodos = createSelector(appState,(state) => state.sampleService);

export const getBsaProfileData = createSelector(appState,(state) => state.application);
export const getUserInfo = createSelector(appState,(state) => state.userInfo);
export const getAppId = createSelector(appState,(state) => state.appId);
export const getLastSavedRoute = createSelector(appState,(state) => state.lastSavedRoute);
export const getlobType = createSelector(appState,(state) => state.lobType);

export const naicsCodes = createSelector(appState, (state) =>
  state.naicsCodes.map((code, index) => {
    return {
      ...code,
      id: index,
      itemName: code.subCode + ' - ' + code.subTitle,
    };
  })
);

export const entityList = createSelector(appState, (state) =>    
    state.entityList.map((entity, index) => {
        return {
            ...entity,
            id: index,
            itemName: entity
        };
    })
)

export const applicant = createSelector(appState, (state) => appState,(state) => state.application.applicant);
export const coApplicant = createSelector(appState, (state) => appState,(state) => state.application.coApplicants);


export const states = createSelector(appState, (state) => {
    const currentStates = [...state.states];
    const texasData = currentStates.find((state) => state.name === 'Texas');
    if (texasData) {
      currentStates.unshift({
        ...texasData,
        id: 0,
      });
    }
    return currentStates
      .map((item, index) => {
        return {
          ...item,
          id: index,
          itemName: item.name,
          abbreviation: item.abbreviation,
        };
      })
      .filter((i) => {
        return i.itemName === 'Texas' && i.id !== 0 ? null : i;
      });
  });


  export const countries = createSelector(appState, (state) => {
    const currentCountries = [...state.country];
    const USAData = currentCountries.find(
      (country) => country.name === 'United States'
    );
    if (USAData) {
      currentCountries.unshift({
        ...USAData,
        id: 0,
      });
    }
    return currentCountries
      .map((item, index) => {
        return {
          ...item,
          id: index,
          itemName: item.name,
          countryAlpha2: item.alpha2Code,
          countryAlpha3: item.alpha3Code,
        };
      })
      .filter((i) => {
        return i.itemName === 'United States' && i.id !== 0 ? null : i;
      });
  });
