import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiHttpService } from "./api-http.service";
import { ApplicationFlow } from '@app/models/application-flow';
import { ApplicationStore } from '@app/views/application/application.page.store';
import { Observable, Subject } from 'rxjs';
import { EmailTemplate } from '@app/models/email-template';
import { AppFacade } from '../store/app.facade';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  profileInfo: any = {};
  currentApplication: any;
  profileInformation$;
  notificationUrl = '/commercial-core/v1/commercial/on-board/application';
  verifyUrl = 'commercial-core/v1/commercial/on-board/application/applicant/profile/activation';

  payload = {
   from: 'commercial_noreply@texascapitalbank.com',
   to: '',
   body: '',
   isHtml: true,
   subject: 'Application',
   attachments: [],
   notificationType: "smtp",
  };

  constructor(private apihttpservice: ApiHttpService, private applicationStore: ApplicationStore, private appFacade: AppFacade ) { 
    this.appFacade.getBsaProfile$.subscribe((res) => this.profileInfo = res);
  }

  contructVerificationPayload(): any {    
    return {
      customerUserId: this.profileInfo.applicant.customerId,
      applicationId: this.profileInfo.applicationId,
      createdDate: this.profileInfo.createdAt,
      createBy: this.profileInfo.createdBy,
      activationDate: new Date(),
      firstName: this.profileInfo.applicant.firstName,
      lastName: this.profileInfo.applicant.lastName,
      email: this.profileInfo.organization.address.businessEmail,
      mobileNumber: this.profileInfo.organization.address.businessPhoneNbr,
      isUserActivated: false,
      activationTryCount: 0,
      userIdentity: this.profileInfo.organization.taxId
    }
  }

  contructNotificationPayload(emailTemplate: EmailTemplate): any {
    this.payload.to = this.profileInfo.organization.address.businessEmail;
    this.payload.body = emailTemplate.emailTemplate;
    return this.payload;
  }

  getCustomerInfo(applicationId, email): Observable<any> {
    return this.apihttpservice.get(`${this.verifyUrl}?email=${email}&applnId=${applicationId}`);
  }

  //This service is to generate token for session id
  generateToken(): Observable<any> {
    return this.apihttpservice.get(`generate-token`);
  }

  getURL(): Observable<any> {
    return this.apihttpservice.post(`${this.verifyUrl}`, this.contructVerificationPayload());
  }

  
  notifyCustomer(appId: any): Observable<any> {
    let subject = new Subject<any>();
    
    this.getURL().subscribe((res) => {      
      let emailTemplate = new EmailTemplate(res.moreInfo);

      this.apihttpservice.postNotification(`${this.notificationUrl}/${appId}/notification`, this.contructNotificationPayload(emailTemplate)).subscribe(
        (res) => subject.next(res),  
        (error) => subject.error(error)
      );
    
    }, (error) => subject.error(error));
    
    return subject.asObservable();      
  }
}
