import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { AuthGuardService } from '@app/core';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () => import('./features/shell-layout/shell-layout.module').then(m => m.ShellLayoutPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'login/callback',
    pathMatch: 'full',
    component: OktaCallbackComponent
  },
  { path: '**', redirectTo: 'home'},  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
