import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Applicant } from '@app/models/applicant';
import { ApiHttpService } from './api-http.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})



export class GlobalService {

  triggerSave$ = new Subject();
  detectScroll$ = new Subject();
  otpTrue$ = new Subject();
  commercialUrl = `${environment.coreServiceUrl}v1/commercial/on-board/application`;
  applicationID = window.location.pathname.split('/')[1];
  constructor(private httpClient: HttpClient, private apiHttpService: ApiHttpService, private route: ActivatedRoute) { }

  triggerSaveForm(flag) {
    this.triggerSave$.next(flag);
  }

  loadCountries(appId: any): Observable<any> {
    return this.apiHttpService.get(this.commercialUrl + '/' + appId + '/country');
  }

  loadStates(appId: any): Observable<any> {
    return this.apiHttpService.get(this.commercialUrl + '/' + appId + '/states');
  }

  loadNaics(appId: any): Observable<any> {
    return this.apiHttpService.get(this.commercialUrl + '/' + appId + '/naics/2022');
  }

  getApplicantProfile() {
    if (this.applicationID !== (null || undefined || '')) {
      return this.apiHttpService.get(this.commercialUrl + '/' + this.applicationID + '/applicant/business');
    } else {
      this.applicationID = window.location.pathname.replace('/', '');
      return this.apiHttpService.get(this.commercialUrl + '/' + this.applicationID + '/applicant/business');
    }
  }

  putApplicantProfile(data) {
    return this.apiHttpService.put(this.commercialUrl + '/' + this.applicationID + '/applicant/business', data);
  }


  getIpAddress() {
    return this.httpClient.get("https://thingproxy.freeboard.io/fetch/http://api.ipify.org/?format=json");
  }

  set_cookie(name, value) {
    document.cookie = name + '=' + value + '; Path=/;';
  }
  delete_cookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  getExternalProfilesData() {
    const url = `${this.commercialUrl}/${this.applicationID}/relationships`;
    return this.apiHttpService.get(url);
  }
}
