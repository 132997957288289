export const FooterLinks = [
    {
        groupHeading: "About us",
        links: [
            {
                name: "Corporate Overview",
                link: "https://www.texascapitalbank.com/who-we-are/our-company/corporate-overview"
            },
            {
                name: "Leadership",
                link: "https://www.texascapitalbank.com/who-we-are/people/leadership"
            },
            {
                name: "Careers",
                link: "https://www.texascapitalbank.com/who-we-are/careers"
            },
            {
                name: "Investor Relations",
                link: "https://investors.texascapitalbank.com/home/default.aspx"
            },
            {
                name: "Newsroom",
                link: "https://www.texascapitalbank.com/who-we-are/newsroom"
            }
        ]
    },
    {
        groupHeading: "Helpful information",
        links: [
            {
                name: "Current Rates",
                link: "https://www.texascapitalbank.com/personal-banking/current-rates"
            },
            {
                name: "Wire & ACH Instructions",
                link: "https://www.texascapitalbank.com/helpful-information/wire-ach-instructions"
            },
            {
                name: "Disclosures",
                link: "https://www.texascapitalbank.com/helpful-information/disclosures"
            },
            {
                name: "Standard Loan Documents",
                link: "https://www.texascapitalbank.com/helpful-information/standard-loan-documents"
            },
            {
                name: "FAQs",
                link: "https://www.texascapitalbank.com/helpful-information/faqs"
            }
        ]
    },
    {
        groupHeading: "Get in touch",
        links: [
            {
                name: "Contact US",
                link: "https://www.texascapitalbank.com/helpful-information/contact-us"
            },
            {
                name: "Locations",
                link: "https://www.texascapitalbank.com/who-we-are/locations"
            },
            {
                name: "Bank Holidays",
                link: "https://www.texascapitalbank.com/helpful-information/bank-holidays"
            },
            {
                name: "Scheduled Maintenance",
                link: "https://www.texascapitalbank.com/helpful-information/scheduled-maintenance"
            },
            {
                name: "LinkedIn",
                link: "https://www.linkedin.com/company/texas-capital-bank"
            }
        ]
    },
    {
        groupHeading: "Consumer banking",
        links: [
            {
                name: "Personal Banking",
                link: "https://www.texascapitalbank.com/personal-banking"
            },
            {
                name: "Bask Bank",
                link: "https://www.baskbank.com/"
            },
            {
                name: "BankDirect",
                link: "https://www.bankdirect.com/"
            }
        ]
    }
];

export const AdditionalFooterLinks = [
    {
        name: "Accessibility Statement",
        link: "https://www.texascapitalbank.com/sites/default/files/documents/accessibility_statement.pdf",
    },
    {
        name: "Member FDIC",
        link: "https://www7.fdic.gov/idasp/confirmation_outside.asp?inCert1=34383",
    },
    {
        name: "Online Privacy Policy",
        link: "https://www.texascapitalbank.com/sites/default/files/documents/Online_Privacy_Policy.pdf",
    },
    {
        name: "Disclosures",
        link: "https://www.texascapitalbank.com/helpful-information/disclosures",
    },
    {
        name: "Privacy & Security Center",
        link: "https://www.texascapitalbank.com/helpful-information/privacy-security-center",
    }
];