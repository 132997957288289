import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationService } from '@app/core/services/notification.service';
import { OverlayEventDetail } from '@ionic/core';


@Component({
  selector: 'app-exit-modal',
  templateUrl: './exit-modal.component.html',
  styleUrls: ['./exit-modal.component.scss'],
})
export class ExitModalComponent implements OnInit {

  @Output() saveexit: any = new EventEmitter();
  constructor(private notificationService: NotificationService) { }

  ngOnInit() {}

  @Input() isSaveAndExitModal = false;

  setOpen(isOpen: boolean) {
    this.isSaveAndExitModal = isOpen;
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    this.isSaveAndExitModal = false;
  }

  Save() {
    //this.notificationService.notifyCustomer();
    this.isSaveAndExitModal = false;
    setTimeout(()=>{this.saveexit.emit(true)},500)
    //

  }
}
