import { Pipe, PipeTransform } from "@angular/core";
import { orderBy } from 'lodash';

@Pipe({
 name: "orderBy"
})
export class OrderByPipe implements PipeTransform {
 transform(array: any): any[] {
  array.sort((a, b) => a.value.extPosition - b.value.extPosition);
  return orderBy(array);
}
}