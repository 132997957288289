import { createAction, props } from '@ngrx/store';
import { SampleModel } from '@app/models';
import { LocationStateData, LocationCountryData } from '@app/models/state-and-country';
import { NaicsCodes } from '@app/models/naicsCodes';
import { GetApplicantProfileData } from '@app/models/onboard.definitions';

const sampleService = '[SAMPLE_SERVICE]';
const userInfoData = '[USER_INFO]';
const getState = '[GET_STATES]';
const getCountry = '[GET_COUNTRIES]';
const naicsCodes = '[GET_NAICS]';
const entityList = '[ENTITY_LIST]';
const bsaProfile = '[BSA_PROFILE]';

//Dynamic Component Switch
export const updateComponentState = createAction(
    '[Component Store] Update Action',
    props<{ componentName, componentState }>()
);

//GET USER INFO
export const userInfo = createAction(`${userInfoData} LOAD DATA`);

export const userInfoSuccess = createAction(
    `${userInfoData} SUCCESS`,
    props<{ userInfo: any }>()
);
export const userInfoFailure = createAction(
    `${userInfoData} FAILURE`,
    props<{ error: any }>()
);


//GET SAMPLE SERVICE
export const sampleServiceData = createAction(`${sampleService} LOAD DATA`);

export const sampleServiceSuccess = createAction(
    `${sampleService} SUCCESS`,
    props<{ todos: SampleModel[] }>()
);
export const sampleServiceFailure = createAction(
    `${sampleService} FAILURE`,
    props<{ error: any }>()
);

export const getStates = createAction(
    `${getState}`,
    props<{ appId: any }>()
);
export const getStatesSuccess = createAction(
    `${getState} SUCCESS`,
    props<{ states: LocationStateData[] }>()
);
export const getStatesFailure = createAction(
    `${getState} FAILURE`,
    props<{ error: any }>()
);

export const getCountries = createAction(
    `${getCountry}`,
    props<{ appId: any }>()
);
export const getCountriesSuccess = createAction(
    `${getCountry} SUCCESS`,
    props<{ countries: LocationCountryData[] }>()
);
export const getCountriesFailure = createAction(
    `${getCountry} FAILURE`,
    props<{ error: any }>()
);

export const loadNaicsCodes = createAction(
    `${naicsCodes}`,
    props<{ appId: any }>()
);
export const loadNaicsCodesSuccess = createAction(
    `${naicsCodes} SUCCESS`,
    props<{ naicsCodes: NaicsCodes[] }>()
);
export const loadNaicsCodesFailure = createAction(
    `${naicsCodes} FAILURE`,
    props<{ error: any }>()
);

export const getEntityList = createAction(
    `${entityList}`,
    props<{ appId: any }>()
);

export const getEntityListSuccess = createAction(
    `${entityList} SUCCESS`,
    props<{ entityList: any[] }>()
);
export const getEntityListFailure = createAction(
    `${entityList} FAILURE`,
    props<{ error: any }>()
);


// APPLICANT
export const getBsaProfile = createAction(
    `${bsaProfile} GET BSA PROFILE`,
);
export const getBsaProfileSuccess = createAction(
    `${bsaProfile} SUCCESS`,
    props<{ application: GetApplicantProfileData }>()
);
export const getBsaProfileFailure = createAction(
    `${bsaProfile} FAILURE`,
    props<{ error: any }>()
);
export const updateBsaProfile = createAction(
    `${bsaProfile} UPDATE`,
    props<{ application: GetApplicantProfileData }>()
);


//SET APPLICATION ID
export const appId = createAction(
    `SAVE APP ID`,
    props<{ appId:number }>()
);

//SET LOB TYPE
export const lobType = createAction(
    `SAVE LOB TYPE`,
    props<{ lobType:string }>()
);

//LAST SAVED ROUTE
export const getLastSavedRoute = createAction(
    `LAST SAVED ROUTE`,
    props<{ lastSavedRoute:any }>()
);

//LAST SAVED ROUTE
export const savedRoute = createAction(
    `SAVED ROUTE`,
    props<{ lastSavedRoute:any, index: any }>()
);

