import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { AppFacade, linkToGlobalState, PreApplicationService } from "@app/core";
import { SampleModel } from "@app/models";
import { Observable } from "rxjs/internal/Observable";
import { Store } from '@ngrx/store';
import { Views } from "@app/models/views";
import { ToastrService } from "@app/core/services/toastr.service";
import { highRiskIndustriesValues } from "@app/models/application-flow";
import { apartmentIdentifierPossibleValues, getApartmentNumberIdentifierKey } from "@app/models/apartmentnumberIdentifiers";
import { ApplicationStore } from "../application/application.page.store";
export interface profileCreation {
    applicantProfile: any,
    preApplicantBusinessProfile: any
}

export interface BsaProfileInfo {
    bsaProfileInformation: any,
    isDataLoaded: boolean;
    dataError: any;
}

export interface ProfileCreationState {
    preApplicantProfile: profileCreation,
    bsaProfileInformation: BsaProfileInfo,
    attemptCount: number;
}


@Injectable()
export class ProfileCreationStore extends ComponentStore<ProfileCreationState> {
    view = Views;
    constructor(
        private preApplicationService: PreApplicationService, 
        private globalStore: Store, 
        private appFacade: AppFacade,
        private toastrService: ToastrService,
        private readonly applicationStore: ApplicationStore,
    ) {
        super({
            preApplicantProfile: {
                applicantProfile: {},
                preApplicantBusinessProfile: {}
            },
            bsaProfileInformation: {
                bsaProfileInformation: {},
                isDataLoaded: false,
                dataError: null,
            },
            attemptCount: 0
        })
        linkToGlobalState(this.state$, 'ProfileCreationStore', this.globalStore);
    }

    createLlcDtos(profileData: any, answersData: any, step: any): any[]{
        let dtos = [];
        if(step === 'preapplication' && answersData?.entityType?.trim() === 'Limited Liability Company (LLC)') {
            dtos.push({
                id: profileData?.organization?.llcDTOS ? profileData?.organization?.llcDTOS[0]?.id : undefined,
                disregardedEntity: answersData['disregardedEntity'] == 'Yes' ? true : false,
                soleMember: answersData['soleMember'] == 'Yes' ? true : false,
                llcName: answersData['disregardedEntity'] == 'Yes' ? answersData['llcName'] : undefined,
                llcTin: answersData['disregardedEntity'] == 'Yes' ? answersData['llcTin']:  undefined,
                llcTaxIdFlag:answersData['disregardedEntity'] == 'Yes' ? answersData['llcTaxIdFlag'] : undefined,
                llcRelationship: answersData['llcTin'] ? 'TAX' : '',
                llcCifNumber: profileData?.organization?.llcDTOS ? profileData?.organization?.llcDTOS[0]?.llcCifNumber : undefined
            });
        } else {
            dtos = profileData?.organization?.llcDTOS ?? [];
        }

        return dtos;
    }

    postCreateProfile(lobtype, applicationId, profile, currentStep: any, currentIndex: any, exitingStep: any) {
        var profileData;
        this.profile$.subscribe((res)=> {profileData = res;});
        var applicationData: any ={};
        if(lobtype){
            applicationData = {
              application: {
                  ...profileData,
                  existingCustomer: false, 
                  extNavPath: [],
                  coApplicants: [],
                  organization: {
                      ...profileData.organization,
                      businessName: profile?.preApplicantBusinessProfile?.businessName,
                      taxId: profile?.preApplicantBusinessProfile?.taxId || "",
                      taxIdFlag: profile?.preApplicantBusinessProfile?.taxIdFlag || "",
                  }
              }
          }
      }else{
        applicationData = {
            application: {
                ...profileData,
                existingCustomer: false,
                extNavPath: [],
                coApplicants: [],
                applicant: {
                    ...profileData?.applicant,
                    relationShipToCompany: profile.applicantProfile.relationShipToCompany,
                    firstName: profile.applicantProfile.firstName,
                    middleName: profile.applicantProfile.middleName,
                    lastName: profile.applicantProfile.lastName || 'N/A',
                    customerBusinessPhoneNbr: profile.applicantProfile.customerBusinessPhone,
                    mobilePhoneNbr: profile.applicantProfile.mobilePhoneNbr,
                    homePhoneNbr: profile.applicantProfile.homePhoneNbr,
                    businessEmail: profile.applicantProfile.businessEmail,
                    address1: profile.applicantProfile.address1 || '',
                    address2: profile.applicantProfile.address2 || '',
                    city: profile.applicantProfile.city || 'Dallas',
                    state: profile.applicantProfile.state || 'Texas',
                    zipCode: profile.applicantProfile.zipCode || '70070',
                    provinces: profile.applicantProfile.provinces,
                    postalCode: profile.applicantProfile.postalCode,
                    country: profile.applicantProfile.country || 'US',
                    customerCif: profile.applicantProfile.customerCif,
                    ccn: profile.applicantProfile.ccn,
                    customerIdentity: profile.applicantProfile.customerIdentity,
                    customerId: profileData?.applicant?.customerId,
                    apartmentNumberId: getApartmentNumberIdentifierKey(profile.applicantProfile.apartmentNumberId?.trim()) || undefined,
                    address: {
                        ...profileData?.applicant?.address,
                        // mailAddress: `${currentController?.address[0].street}, ${currentController?.address[0]?.houseNumber}`,
                        mailAddress: `to be determined`,
                        address1: profile.applicantProfile.address1,
                        address2: profile.applicantProfile.address2,
                        city: profile.applicantProfile.city,
                        state: profile.applicantProfile.state,
                        country: profile.applicantProfile.country,
                        stateCode: profile.applicantProfile.stateCode || 'TX',
                        zipCode: profile.applicantProfile.zipCode,
                        provinces: profile.applicantProfile.provinces,
                        postalCode: profile.applicantProfile.postalCode,
                        mobilePhoneNbr: profile.applicantProfile.mobilePhoneNbr,
                        homePhoneNbr: profile.applicantProfile.homePhoneNbr,
                        customerBusinessPhone: profile.applicantProfile.customerBusinessPhone,
                        businessEmail: profile.applicantProfile.businessEmail,
                        personalEmail: profile.applicantProfile.personalEmail || "",
                        businessPhone: profile.applicantProfile.customerBusinessPhone,
                        apartmentNumberId: getApartmentNumberIdentifierKey(profile.applicantProfile.apartmentNumberId?.trim()) || undefined,
                    },
                },
                organization: {
                    ...profileData.organization,
                    branchName: profile?.preApplicantBusinessProfile?.branchName,
                    branchOfficerName: profile?.preApplicantBusinessProfile?.branchOfficerName || null,
                    businessName: profile?.preApplicantBusinessProfile?.businessName,
                    isSubsidiary: null,
                    parentCompany: profile?.preApplicantBusinessProfile?.parentCompany || null,
                    businessPhoneNbr: profile?.applicantProfile?.customerBusinessPhone,
                    businessFaxNumber: profile?.preApplicantBusinessProfile?.businessFaxNumber,
                    naicsDesc: profile?.preApplicantBusinessProfile?.naicsDesc,
                    bsaType: "standard",
                    llcDTOS: this.createLlcDtos(profileData, profile.preApplicantBusinessProfile, currentStep),
                    layerEntities: profile?.preApplicantBusinessProfile.layerEntities,
                    businessDescription: profile?.preApplicantBusinessProfile?.businessDescription,
                    businessProhibitedCategory: (profile?.preApplicantBusinessProfile?.businessProhibitedCategory),
                    businessExceptionCategory: (profile?.preApplicantBusinessProfile?.businessExceptionCategory),
                    entityType: profile?.preApplicantBusinessProfile?.entityType || "Financial Institution",
                    businessWebsite: profile?.preApplicantBusinessProfile?.businessWebsite || "",
                    taxId: profile?.preApplicantBusinessProfile?.taxId || "",
                    taxIdFlag: profile?.preApplicantBusinessProfile?.taxIdFlag || "",
                    naicsCode: (profile?.preApplicantBusinessProfile?.naicsDesc)?.split('-')[0].trim(),
                    businessShortName: profile?.preApplicantBusinessProfile?.businessShortName || "",
                    prohibitedHighRisk: this.getIndustryHighRisk(profile?.preApplicantBusinessProfile?.businessExceptionCategory, profile?.preApplicantBusinessProfile?.businessProhibitedCategory),
                    industryHighRisk: this.getIndustryHighRisk(profile?.preApplicantBusinessProfile?.businessExceptionCategory, profile?.preApplicantBusinessProfile?.businessProhibitedCategory),
                    address: {
                        ...profileData?.organization.address,
                        businessPhoneNbr: profile.applicantProfile.customerBusinessPhone,
                        businessFaxNumber: profile?.preApplicantBusinessProfile?.businessFaxNumber,
                        businessEmail: profile.applicantProfile.businessEmail
                    },
                },
            }
        }
      }

        var coApplicant = [...profileData.coApplicants];
        const newArr = coApplicant.map((object,i) => {
            if (object.customerId === profileData.applicant.customerId) {
              return {...applicationData.application.applicant};
            }
            return object;
        });
       applicationData = {application:{...applicationData.application, coApplicants: newArr}}
        this.effect(() => {
            if (applicationId == null) {
                return this.preApplicationService.postProfile(applicationData).pipe(
                    tapResponse(
                        (bsaresponse: any) => {
                            this.appFacade.updateBsaProfile(bsaresponse?.application);
                            this.patchState(() => ({
                                bsaProfileInformation: {
                                    bsaProfileInformation: bsaresponse?.application,
                                    isDataLoaded: true,
                                    dataError: false,
                                }
                            }))
                            this.toastrService.success('Data Saved Succesfully');
                            if(currentStep === exitingStep) {window.location.href="https://www.texascapitalbank.com/commercial-banking"}
                        },
                        (error: any) => {
                            this.patchState(() => ({
                                bsaProfileInformation: {
                                    bsaProfileInformation: {},
                                    isDataLoaded: false,
                                    dataError: error,
                                }
                            }))
                            this.toastrService.success('Error!. Please try again');
                        }
                    )
                )
            } else {
                return this.preApplicationService.putApplicantProfile(applicationId, applicationData).pipe(
                    tapResponse(
                        (bsaresponse: any) => {
                            this.appFacade.updateBsaProfile(bsaresponse?.application);
                            this.toastrService.success('Data Saved succesfully');
                            //delete applicationData['extNavPath'];
                            //this.appFacade.updateBsaProfile(bsaresponse?.application);
                            this.patchState(() => ({
                                bsaProfileInformation: {
                                    bsaProfileInformation: bsaresponse?.application,
                                    isDataLoaded: true,
                                    dataError: false,
                                }
                            }))
                            if(currentStep === exitingStep) {window.location.href="https://www.texascapitalbank.com/commercial-banking"}
                        },
                        (error: any) => {
                            this.patchState(() => ({
                                bsaProfileInformation: {
                                    bsaProfileInformation: {},
                                    isDataLoaded: false,
                                    dataError: error,
                                }
                            }))
                            this.toastrService.success('Error!. Please try again');
                        }
                    )
                )
            }

        })
    }

    getIndustryHighRisk(highRisk: string, prohibitedCategory: string) {
      const isProhibited: boolean = prohibitedCategory?.split('|').some((x: any) => highRiskIndustriesValues['businessProhibitedCategory']?.includes(x));
      const isHighRisk = highRisk?.split('|').some((x: any) => highRiskIndustriesValues['businessExceptionCategory']?.includes(x));
      sessionStorage.setItem('isHighRisk', `${isProhibited || isHighRisk ? true : false}`);
      return  isProhibited || isHighRisk ? true : false;
    }


    getProfile() {
        this.effect(() => {
            return this.appFacade.getBsaProfile$.pipe(
                tapResponse(
                    (data: any) => {
                        var applicantProfileData = {
                            ...data.applicant.address,...data.applicant, "customerBusinessPhone": data.applicant?.address.customerBusinessPhone, "address1": data.applicant?.address.address1, "address2": data.applicant?.address.address2, "city": data.applicant?.address.city, "state": data.applicant?.address.state, "zipCode": data.applicant?.address.zipCode, "country": data.applicant?.address.country, "customerIdentity": data.applicant?.customerIdentity, "apartmentNumberId": getApartmentNumberIdentifierKey(data.applicant?.address.apartmentNumberId)
                        }
                        this.patchState(() => ({
                            bsaProfileInformation: {
                                bsaProfileInformation: data,
                                isDataLoaded: true,
                                dataError: false,
                            }
                        })),
                            this.patchState(() => ({
                                preApplicantProfile: {
                                    applicantProfile: applicantProfileData,
                                    preApplicantBusinessProfile: {
                                        createdAt: data.createdAt,
                                        createdBy: data.createdBy,
                                        ...data.organization
                                    }
                                },
                            }))
                    },
                    (error: any) => {
                        this.patchState(() => ({
                            preApplicantProfile: {
                                applicantProfile: null,
                                preApplicantBusinessProfile: null
                            },
                        }))
                    }
                )
            )
        })
    }

    increaseAttemptCount(incrementAttemptCount: number): void {
        this.patchState({
            attemptCount: incrementAttemptCount
        });
    }


    addApplicantProfileData(applicationId, applicantProfile: any, currentStep: any, currentIndex: any, exitingStep:any) {
      this.patchState((state: ProfileCreationState) => {
            const data = {
                preApplicantProfile: {
                    applicantProfile: applicantProfile,
                    preApplicantBusinessProfile: state.preApplicantProfile.preApplicantBusinessProfile
                }
            }
            this.postCreateProfile(false,applicationId, data?.preApplicantProfile, currentStep, currentIndex, exitingStep);
            this.applicationStore.putApplicationStep(currentStep, currentIndex, true);
            return data;
        })
    }

    addApplicantId(id: any) {
        this.patchState((state: ProfileCreationState) => {
            const data = {
                preApplicantProfile: {
                    applicantProfile: {
                        ...state.preApplicantProfile.applicantProfile,
                        customerIdentity: [
                            ...state.preApplicantProfile.applicantProfile.customerIdentity ?? [],
                            id
                        ]
                    },
                    preApplicantBusinessProfile: state.preApplicantProfile.preApplicantBusinessProfile
                }
            }
            return data;
        })
    }

    addpreApplicantBusinessProfile(lobType: boolean,applicationId, preApplicantBusinessProfile: any,currentStep: any, currentIndex: any, exitStep:any) {
        this.patchState((state: ProfileCreationState) => {
            const data = {
                preApplicantProfile: {
                    applicantProfile: state.preApplicantProfile.applicantProfile,
                    preApplicantBusinessProfile: preApplicantBusinessProfile
                }
            }
            if(applicationId != null) {
                this.postCreateProfile(lobType,applicationId, data?.preApplicantProfile, currentStep, currentIndex, exitStep);
                this.applicationStore.putApplicationStep(currentStep, currentIndex, true);
            }
            return data;
        })
    }


    cleanState() {
        this.setState((state: ProfileCreationState) => {
            return {
                preApplicantProfile: {
                    applicantProfile: {},
                    preApplicantBusinessProfile: {}
                },
                bsaProfileInformation: {
                    bsaProfileInformation: {},
                    isDataLoaded: false,
                    dataError: null,
                },
                attemptCount: 0
            }
        })
    }

    readonly applicantProfile$: Observable<any | null> = this.select((state) => state.preApplicantProfile.applicantProfile);
    readonly preApplicantBusinessProfile$: Observable<SampleModel[] | null> = this.select((state) => state.preApplicantProfile.preApplicantBusinessProfile);
    readonly applicationId$: Observable<SampleModel[] | null> = this.select((state) => state.bsaProfileInformation?.bsaProfileInformation?.applicationId);
    readonly attemptCount$:  Observable<number | null> = this.select((state) => state.attemptCount);
    readonly profile$:  Observable<number | null> = this.select((state) => state.bsaProfileInformation.bsaProfileInformation);
}
