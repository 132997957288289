import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppFacade, GlobalService } from '@app/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {
  constructor() {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for(let registration of registrations) {
       registration.unregister()
     } })
  }
}
