import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppFacade } from '@app/core';
import { ToastrService } from '@app/core/services/toastr.service';
import { combineLatest } from 'rxjs';


@Component({
  selector: 'app-identification-details',
  templateUrl: './identification-details.component.html',
  styleUrls: ['./identification-details.component.scss'],
})
export class IdentificationDetailsComponent implements OnInit, OnChanges {
  @Input() identityQuestions: any;
  @Input() ids: any;
  @Output() customerid: any = new EventEmitter();
  states: any[];
  countries: any[];
  questions: any[];

  tableHeaders = [
    { key: 'type', value: 'ID TYPE' },
    { key: 'number', value: 'ID NUMBER' },
    { key: 'issued', value: 'ID ISSUED' },
    { key: 'expires', value: 'ID EXPIRES' },
    { key: 'state', value: 'STATE' },
    { key: 'country', value: 'COUNTRY' },
    { key: 'actions', value: 'ACTIONS' }
  ];

  identificationFormGroup = this.fb.group({
    identifications: this.fb.array([])
  });

  constructor(private appFacade: AppFacade, private fb: FormBuilder, private toastr: ToastrService) {}

  get identifications() {
    return this.identificationFormGroup.controls['identifications'] as FormArray;
  }

  ngOnInit(): void {
    combineLatest([this.appFacade.states$, this.appFacade.countries$]).subscribe(([states, countries]) => {
      this.states = states;
      this.countries = countries;
    });
    this.initQuestions();
  }

  ngOnChanges() {
    if(this.ids.customerIdentity && this.identificationFormGroup.controls['identifications'].controls.length === 0) {
      this.formatIds(this.ids.customerIdentity);
    }
  }

  getDateValidity(condition: string) {
    switch (condition) {
      case 'today':
        return this.formatDate(new Date());
      break;
      case 'tomorrow':
        return this.formatDate(new Date(new Date().setDate(new Date().getDate() + 1)));
      break;
      case '18yearsago':
        return this.formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 18)));
       break;
      case 'start1800':
        return this.formatDate(new Date(1800, 0, 1));
      break;
      case 'start120':
        return this.formatDate(new Date(new Date().setFullYear(new Date().getFullYear() - 120)));
      break;
      case 'end100':
        return this.formatDate(new Date(new Date().setFullYear(new Date().getFullYear() + 1000)));
      break;
    }
    return null;
  }
  formatDate (dateToFormat) {
    if(dateToFormat) {
      var year = dateToFormat.getFullYear();
      var month = dateToFormat.getMonth() + 1;
      var dt = dateToFormat.getDate();
      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }
       return year + '-' + month + '-' + dt;
     }
  }
  formatIds(customerIdentity: any[]) {
    const prevData = [];
    customerIdentity.forEach((ci) => {
      ci = {...ci};
      ci.expirationDate = this.formatDates(ci.expirationDate);
      ci.issuedDate = this.formatDates(ci.issuedDate);
      prevData.push(this.createGroup(ci));
    });
    this.identificationFormGroup = this.fb.group({
      identifications: this.fb.array(prevData)
    });
  }

  deleteRow(index: number) {
    this.identifications.removeAt(index);
  }

  addNew() {
    this.identifications.push(this.createGroup());
  }

  createGroup(data?): FormGroup {
    const identificationForm = new FormGroup({});
    this.identityQuestions?.dialogData.forEach(element => {
      const val = data ? data[element.questionCode] : null;
      identificationForm.addControl(element.questionCode, new FormControl(
        { disabled: element.disabled, value: val},
          [Validators.maxLength(element.maxLength), ...this.getValidators(element)]), element.questionType);
    });
    const identityTypeValue = identificationForm.get('identityType').value;
    if(identityTypeValue) {
      this.updateFormControls(identityTypeValue, identificationForm);
    }
    return identificationForm;
  }

  updateFormControls(identityTypeValue, row) {
    if(identityTypeValue === 'Passport' || identityTypeValue === 'Resident ID/Green card') {
      row.get('issuedState').setValue(null);
      row.get('issuedState').disable();
      row.get('issuedCountry').enable();
    }
    else if(identityTypeValue === 'State Issued ID' || identityTypeValue === `Driver's License`) {
      row.get('issuedCountry').setValue(null);
      row.get('issuedState').enable();
      row.get('issuedCountry').disable();
    } else {
      row.get('issuedState').setValue(null);
      row.get('issuedCountry').setValue(null);
      row.get('issuedState').disable();
      row.get('issuedCountry').disable();
    }
  }

  initQuestions() {
    this.questions = this.identityQuestions.dialogData.map((dialogData) => {
      if(dialogData.questionCode === 'issuedState') {
        return { ...dialogData, possibleValues: this.states };
      } else if(dialogData.questionCode === 'issuedCountry') {
        return {...dialogData, possibleValues: this.countries };
      }
      return dialogData;
    });
  }
  getValidators(question: any): any {
    const arrVals = [];
    if(question['mandatory']){
      arrVals.push(Validators.required);
    }
    return arrVals;
  }
  markFormGroupTouched(form: FormArray) {
    (<any>Object).values(form.controls).forEach((control: any) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  updateFieldPermissions(qc: string, event: any, index: number) {
    const identityTypeValue = event.target.value;
    if(qc === 'identityType' && identityTypeValue) {
      const row = this.identificationFormGroup.controls['identifications'].controls[index];
      this.updateFormControls(identityTypeValue, row);
    }
  }

  formatDates(date: string): string {
    return date?.split('T')[0] ?? '';
  }

  saveIdentification() {
    const data = this.identificationFormGroup.get('identifications').value;
    if (this.identificationFormGroup.valid && data && data.length) {
      data.forEach((it: any) => {
        it.expirationDate = this.formatDates(it.expirationDate);
        it.issuedDate = this.formatDates(it.issuedDate);
      });
      this.customerid.emit(data);
      this.toastr.success('Identifications Saved');
    } else {
      this.toastr.error('Enter required fields');
      this.markFormGroupTouched(this.identificationFormGroup.get('identifications') as FormArray);
    }
  }
}
