import { Injectable } from '@angular/core';
import { ApiHttpService } from '@app/core/services/api-http.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class TrustService {
  onboardUrl = `${environment.coreServiceUrl}v1/commercial/on-board/application`;
  constructor(private apiHttpService: ApiHttpService) {}

  saveTrustRelatedQsn(trustData: any) {
    if (trustData.trustInfoId) {
      return this.apiHttpService.put(`${this.onboardUrl}/${trustData.applicationId}/applicant/trust`, trustData);
    } else {
      return this.apiHttpService.post(`${this.onboardUrl}/${trustData.applicationId}/applicant/trust`, trustData);
    }
  }

  getTrustData(applicationId: any) {
    return this.apiHttpService.get(`${this.onboardUrl}/${applicationId}/applicant/trust`);
  }

  deleteTrustData(trustCustomerId: any, applicationId: any) {
    return this.apiHttpService.delete(`${this.onboardUrl}/${applicationId}/applicant/trust`);
  }
}