import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ApiHttpService } from './api-http.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DocumentUploadService {
  documentServiceURL = `document-mgmt/v1/commercial/on-board/documents/`;

  constructor(private apiHttpService: ApiHttpService) {}

  getDocumentByEntity(entityType: string, appId: any, layerEntityName: string = 'Applicant') {
    return this.apiHttpService.get(
      // eslint-disable-next-line max-len
      `commercial-admin/v1/commercial/admin/${appId}/documents/entity?entityType=${entityType.trim()}&layerEntityName=${layerEntityName}`
    );
  }

  getDocumentByEntityInvesmentBanking(entityType: string, appId: any, layerEntityName: string = 'Applicant', assetClass: string) {
    //setting Session-Guid in headers
    const headers = new HttpHeaders().set('Session-Guid', new Date().valueOf().toString());
    const requestOptions: Object = {
      headers: headers
    }
    return this.apiHttpService.get(
      `commercial-admin/v1/commercial/admin/${appId}/documents/entity?entityType=${entityType}&layerEntityName=${layerEntityName}&assetClass=${assetClass}`,
      requestOptions
    );
  }

  getUploadedDocuments(
      applicationId: number,
      source: string = 'document upload',
      layerEntityName: string = 'Applicant'
    ): Observable<any[]> {
    return this.apiHttpService.get(
      `${this.documentServiceURL}taglist/${applicationId}?source=${source}&layerEntityName=${layerEntityName}`
    );
  }

  getSingleDocument(appId: string, documentName: string, documentTag: string, layerEntityName: string, customerId?: string) {
    const httpOptions = { responseType: 'blob' };

    const body = this.getRequestBody(
      appId,
      documentName,
      documentTag,
      layerEntityName,
      customerId
    );

    const url = `${this.documentServiceURL}download/${appId}`;

    return this.apiHttpService.post(url, body, httpOptions);
  }

  deleteDocument(appId: string, documentName: string, documentTag: string, layerEntityName: string, customerId?: string) {
    const body = this.getRequestBody(
      appId,
      documentName,
      documentTag,
      layerEntityName,
      customerId
    );
    return this.apiHttpService.post(`${this.documentServiceURL}delete/${appId}`, body);
  }

  uploadDocument(req: any, applicationId: number) {
    return this.apiHttpService.post(`${this.documentServiceURL}document/upload/${applicationId}`, req);
  }

  formatLayerEntities(organization: any): any[] {
    const formattedLayerEntities: any[] = [];

    if (organization) {
      formattedLayerEntities.push({
        layerNumber: 0,
        entityName: 'Applicant',
        entityType: organization.entityType,
        existingEntity: organization.existingCustomer,
        assetClass: organization.assetClass ?? undefined,
        cifNumber: null,
      });

      if (organization.layerEntities && organization.layerEntities.length > 0) {
        organization.layerEntities.slice().sort((a: any, b: any) => a.layerNumber - b.layerNumber).forEach((layerEntity: any) => {
            formattedLayerEntities.push({
              layerNumber: layerEntity.layerNumber + 1,
              entityName: layerEntity.entityName,
              entityType: layerEntity.entityType,
              existingEntity: layerEntity.existingEntity,
              assetClass: organization.assetClass ?? undefined,
              cifNumber: layerEntity.cifNumber,
            });
          });
      }
    }
    return formattedLayerEntities;
  }

  getRequestBody(appId: string, documentName: string, documentTag: string, layerEntityName: string, customerId?: string): any {
    let body;
    if (customerId) {
      body = {
        applicationId: appId,
        documentName,
        documentTag,
        layerEntityName,
        customerId,
      };
    } else {
      body = {
        applicationId: appId,
        documentName,
        layerEntityName,
        documentTag,
      };
    }
    return body;
  }
}
