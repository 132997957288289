//Core Imports
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AvatarModule } from 'ngx-avatar';

//NgRX Sore
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from '@app/core/store';
import { AppEffects, componentStateReducer } from '@app/core';
import { storeDevToolsImport } from '../app/core/store/app.buildspecifcs';

//Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import { CommonModule } from '@angular/common';
  
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    HttpClientModule,
    IonicModule.forRoot(), 
    AppRoutingModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    /* Just to test the component store on switch*/
    //StoreModule.forRoot({ viewState: componentStateReducer}),
    ...storeDevToolsImport,
    // StoreRouterConnectingModule.forRoot({
    //   navigationActionTiming: NavigationActionTiming.PostActivation,
    // }),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule,
    CoreModule,
    SharedModule,
    AvatarModule,
    NgxDatatableModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  schemas: [NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA],

  exports: [GooglePlaceModule],

  bootstrap: [AppComponent],
})
export class AppModule {}
