import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Applicant } from '@app/models/applicant';
import { ApiHttpService } from './api-http.service';

@Injectable({
  providedIn: 'root'
})
export class PreApplicationService {

  commercialUrl = `${environment.coreServiceUrl}v1/commercial/on-board/application`;

  constructor(private apiHttpService: ApiHttpService) { }

  postProfile(application: Applicant) {
    return this.apiHttpService.post(this.commercialUrl + '/applicant/business', application
    ).pipe(catchError(this.handleError));
  }

  updateProfile(application: any, applicationId: any) {
    return this.apiHttpService.put(this.commercialUrl + '/' + applicationId + '/applicant/business', application
    ).pipe(catchError(this.handleError));
  }

  postAdditionalProfile(application: Applicant, appId: any) {
    return this.apiHttpService.post(this.commercialUrl + `/${appId}/applicant/additional-profile`, application
    ).pipe(catchError(this.handleError));
  }

  postUltimateData(application: Applicant, appId: any) {
    return this.apiHttpService.put(this.commercialUrl + `/${appId}/applicant/business/ubo`, application).pipe(catchError(this.handleError));
  }

  getApplicantProfile(applicationId: number) {
    return this.apiHttpService.get(this.commercialUrl + '/' + applicationId +'/applicant/business').pipe(catchError(this.handleError));
  }

  putApplicantProfile(applicationId: number, data) {
    return this.apiHttpService.put(this.commercialUrl + '/'+ applicationId + '/applicant/business', data).pipe(catchError(this.handleError));
  }

  updateNavigation(payload: any) {
    return this.apiHttpService.post(
      this.commercialUrl + `/navigation`,
      payload
    );
  }

  getNavigation(applicationId: any) {
    return this.apiHttpService.get(
      this.commercialUrl + `/${applicationId}/navigation?applicationType=External`,
    );
  }

  getEntityList(applicationId: any): Observable<any> {
    return this.apiHttpService.get(this.commercialUrl + '/' + applicationId + '/entity-list');
  }


  getProducts(applicationId: number) {
    return this.apiHttpService.get(this.commercialUrl + '/' + applicationId + '/account').pipe(catchError(this.handleError));
  }

  putProduct(data, appId: any) {
    return this.apiHttpService.put(this.commercialUrl + `/${appId}/account`, data).pipe(catchError(this.handleError));
  }


  handleError(error: any) {
    let errorMessage = '';
    (error.error instanceof ErrorEvent) ? errorMessage = `ClienSideError: ${error.error.message}` : errorMessage = `ServerSide Error Code: ${error.status}\nMessage: ${error.message}`;
    return throwError(() => { return errorMessage });
  }
}
