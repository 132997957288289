import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { AvatarModule } from 'ngx-avatar';
import { NgOtpInputModule } from  'ng-otp-input';

//Components
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxMaskModule } from 'ngx-mask';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DynamicFormPreapplicationComponent } from './components/dynamic-form-preapplication/dynamic-form-preapplication.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { FooterComponent } from './components/footer/footer.component';
import { IdentificationDetailsComponent } from './components/identification-details/identification-details.component';
import { ImageHeaderComponent } from './components/image-header/image-header.component';
import { LandingHeaderComponent } from './components/landing-header/landing-header.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { VerifyApplicationComponent } from './components/verify-application/verify-application.component';
import { OrderByPipe } from './pipe/orderby.pipe';
import { BreadCrumbComponent } from '@app/shared/components/breadcrumb/breadcrumb.component';
import { ApplicationStore } from '@app/views/application/application.page.store';
import { SanitizePipe } from './pipe/sanitize.pipe';
import { ExitModalComponent } from './components/exit-modal/exit-modal.component';
import { ConditionsComponent } from './components/conditions/conditions.component';
import { TooltipsModule } from 'ionic4-tooltips';
import { ProfileCreationStore } from '@app/views/profile-creation/profile-creation.store';
import {LayerEntitiesComponent} from "@app/shared/components/layer-entities/layer-entities.component";
import { ProfiledeleteModalComponent } from "@app/shared/components/profiledelete-modal/profiledelete-modal.component";
@NgModule({
    declarations: [
        TopNavComponent,
        FooterComponent,
        ImageHeaderComponent,
        LandingHeaderComponent,
        IdentificationDetailsComponent,
        DynamicFormComponent,
        DynamicFormPreapplicationComponent,
        VerifyApplicationComponent,
        BreadCrumbComponent,
        ExitModalComponent,
        ConditionsComponent,
        OrderByPipe,
        SanitizePipe,
        LayerEntitiesComponent,
        ProfiledeleteModalComponent
    ],
  imports: [
    CommonModule,
    AvatarModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    NgSelectModule,
    GooglePlaceModule,
    IonicModule,
    RouterModule,
    NgOtpInputModule,
    NgxMaskModule.forRoot(),
    TooltipsModule.forRoot(),
  ],
  exports: [
    TopNavComponent,
    FooterComponent,
    ImageHeaderComponent,
    LandingHeaderComponent,
    DynamicFormComponent,
    DynamicFormPreapplicationComponent,
    BreadCrumbComponent,
    VerifyApplicationComponent,
    ExitModalComponent,
    SanitizePipe,
    ProfiledeleteModalComponent
  ],
  providers: [ApplicationStore,ProfileCreationStore],
  schemas:[CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule { }
