import { Injectable } from '@angular/core';
import { ApiHttpService } from './api-http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {
    commercialUrl = 'commercial-core/v1/commercial/on-board/application/';
    verificationUrl = 'applicant/profile/activation/verify';
    validationUrl='applicant/profile/activation/validate/activation-code';

    constructor(private apiHttpService: ApiHttpService) {}

    verifyCustomer(payload: any, appId: any): Observable<any> {
        return this.apiHttpService.post(this.commercialUrl + appId + '/' + this.verificationUrl, payload);
    }

    validateCustomer(payload: any, appId: any): Observable<any> {
      return this.apiHttpService.post(this.commercialUrl + appId + '/' + this.validationUrl, payload);
  }
}
