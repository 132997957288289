import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OverlayEventDetail } from '@ionic/core';

@Component({
  selector: 'app-profiledelete-modal',
  templateUrl: './profiledelete-modal.component.html',
  styleUrls: ['../exit-modal/exit-modal.component.scss'],
})
export class ProfiledeleteModalComponent implements OnInit {

  @Output() requestDeleteExit: any = new EventEmitter();
  @Output() cancel: any = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  @Input() isDeleteModalOpen =false;
  @Input() index;
  @Input() card;

  setOpen(isOpen: boolean) {
    this.isDeleteModalOpen = isOpen;
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    this.isDeleteModalOpen = false;
    this.cancel.emit(false);
  }

  Save() {
    this.isDeleteModalOpen = false;
    setTimeout(()=>{this.requestDeleteExit.emit([this.index,this.card,this.isDeleteModalOpen])},500)
  }

}
