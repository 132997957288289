import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}

  addAuthHeader(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token;

    if(this.check_cookie_name('api-token')) {
      token = this.check_cookie_name('api-token');
    } else {
      token =this.auth.getAccessToken();
    }
    var applicationid;
    var sessionguid;
    var ipaddress;
    var email;
    (this.check_cookie_name('application-id'))?applicationid = this.check_cookie_name('application-id'):applicationid = 'invalid';
    (this.check_cookie_name('session-guid'))?sessionguid = this.check_cookie_name('session-guid'):sessionguid = 'invalid';
    (this.check_cookie_name('ip-address'))?ipaddress = this.check_cookie_name('ip-address'):ipaddress = 'invalid';
    (this.check_cookie_name('email'))?email = this.check_cookie_name('email'):email = 'invalid';
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        "application-id": applicationid,
        "session-guid":sessionguid,
        "ip-address":ipaddress,
        "X-Frame-Options":"DENY",
        "email":email
      }
    });
    return next.handle(request);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    return this.addAuthHeader(request, next);
  }

  check_cookie_name(name) 
    {
      var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
      if (match) {
        return match[2]
      }
      else{
        return false;
      }
  }
}