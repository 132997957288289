import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as AppActions from './app.actions';
import * as appSelector from './app.selector';

@Injectable({
  providedIn: 'root',
})

export class AppFacade { 
    constructor(private store: Store) {}
    
    //sampleServiceTodos$= this.store.pipe(select(appSelector.getSampleTodos));
    getUserInfo$= this.store.pipe(select(appSelector.getUserInfo));
    getBsaProfile$= this.store.pipe(select(appSelector.getBsaProfileData));
    states$ = this.store.pipe(select(appSelector.states));
    countries$ = this.store.pipe(select(appSelector.countries));
    naicsCodes$ = this.store.pipe(select(appSelector.naicsCodes));
    entityList$ = this.store.pipe(select(appSelector.entityList));
    applicant$ = this.store.pipe(select(appSelector.applicant));
    appId$ = this.store.pipe(select(appSelector.getAppId));
    lastSavedRoute$ = this.store.pipe(select(appSelector.getLastSavedRoute));
    coApplicants$ = this.store.pipe(select(appSelector.coApplicant))
    lobType$ = this.store.pipe(select(appSelector.getlobType));

    getSampleServiceTodos(): void {
      this.store.dispatch(AppActions.sampleServiceData());
    }

    setApplicationID(id: number): void {
      this.store.dispatch(AppActions.appId({appId: id}));
    }

    setLobType(lobType: string): void {
      this.store.dispatch(AppActions.lobType({lobType: lobType}));
    }

    getLastSavedRoute(route: any): void {
      this.store.dispatch(AppActions.getLastSavedRoute({lastSavedRoute: route}));
    }

    getSavedRoute(route: any, index: any): void {
      this.store.dispatch(AppActions.savedRoute({lastSavedRoute: route, index: index}));
    }

    getUserInfo(): void {
      this.store.dispatch(AppActions.userInfo());
    }

    getBsaProfile(): void {
      this.store.dispatch(AppActions.getBsaProfile());
    }

    updateBsaProfile(profileData): void {
      this.store.dispatch(AppActions.updateBsaProfile({application: profileData}));
    }

    getStates(appId: any): void {
      this.store.dispatch(AppActions.getStates({ appId }))
    }

    getCountries(appId: any): void {
      this.store.dispatch(AppActions.getCountries({ appId }))
    }

    getNaicsCodes(appId: any): void {
      this.store.dispatch(AppActions.loadNaicsCodes({ appId }))
    }

    getEntityList(appId: any) {
      this.store.dispatch(AppActions.getEntityList({ appId }));
    }
}