import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppActions from './app.actions';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { OktaAuth } from "@okta/okta-auth-js";
import { OKTA_AUTH } from '@okta/okta-angular';
import { UserInfoModel } from '@app/models';
import { SampleServiceService, AuthService, GlobalService, AppFacade } from '@app/core';
import { PreApplicationService } from '@app/core';
import { GetApplicantProfileData } from '@app/models/onboard.definitions';
import { AdditionalProfilesComponent } from '@app/views/application/components/additional-profiles/additional-profiles.component';

@Injectable()
export class AppEffects {
    currentRoute = window.location.pathname.split('/')[1]

    constructor(
        private actions$: Actions,
        private sampleService: SampleServiceService,
        private auth: AuthService,
        private preApplicationService: PreApplicationService,
        private gloalService: GlobalService,
        private appFacade: AppFacade,
        @Inject(OKTA_AUTH) public oktaAuth: OktaAuth) { }


    // GetUserInfo
    getUserInfo$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.userInfo),
            switchMap(() =>
                this.auth.getUserInfo().pipe(
                    map((userInfo: UserInfoModel) =>
                        AppActions.userInfoSuccess({ userInfo })
                    ),
                    catchError((error) => of(AppActions.userInfoFailure({ error })))
                )
            )
        );
    });


    // GetSampleTodos
    getSampleToDo$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.sampleServiceData),
            switchMap(() =>
                this.sampleService.get().pipe(
                    map((todos: any) =>
                        AppActions.sampleServiceSuccess({ todos })
                    ),
                    catchError((error) => of(AppActions.sampleServiceFailure({ error })))
                )
            )
        );
    });

    //GET STATES
    getStates$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.getStates),
            switchMap(({ appId }) =>
                this.gloalService.loadStates(appId).pipe(
                    map((states: any) => AppActions.getStatesSuccess({ states })),
                    catchError((error) => of(AppActions.getStatesFailure({ error })))
                )
            )
        );
    });

    //GET COUNTRIES
    getCountries$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.getCountries),
            switchMap(({ appId }) =>
                this.gloalService.loadCountries(appId).pipe(
                    map((countries: any) => AppActions.getCountriesSuccess({ countries })),
                    catchError((error) => of(AppActions.getCountriesFailure({ error })))
                )
            )
        );
    });

    //NAICS CODE
    loadNaicsCodes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.loadNaicsCodes),
            switchMap(({ appId }) =>
                this.gloalService.loadNaics(appId).pipe(
                    map((naicsCodes: any) => AppActions.loadNaicsCodesSuccess({ naicsCodes })),
                    catchError((error) => of(AppActions.loadNaicsCodesFailure({ error })))
                )
            )
        );
    });


    //ENTITY LIST
    getEntityList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.getEntityList),
            switchMap(({ appId }) => {                
               return this.preApplicationService.getEntityList(appId).pipe(
                    map((entityList: any) => AppActions.getEntityListSuccess({ entityList: entityList.entityList })),
                    catchError((error) => of(AppActions.getEntityListFailure({ error })))
                )
            }
            )
        );
    });


    //GET BSA PROFILE
    getBsaProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.getBsaProfile),
            switchMap(() => {
                return this.gloalService.getApplicantProfile().pipe(
                    map((application: GetApplicantProfileData) => {
                        this.appFacade.setApplicationID(application.applicationId);
                        this.appFacade.setLobType(application.lobType);
                        (application.extNavPath) ? application.extNavPath = application.extNavPath : application.extNavPath = ['profile', 'application'];
                        this.appFacade.getLastSavedRoute(application.extNavPath);
                        //delete application['extNavPath'];
                        return AppActions.getBsaProfileSuccess({ application })
                    }),
                    catchError((error) => of(AppActions.getBsaProfileFailure({ error })))
                )
            })
        );
    });


    saveCurrentRoute$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AppActions.savedRoute),
            withLatestFrom(this.appFacade.getBsaProfile$),
            switchMap(([{ lastSavedRoute, index }, application]) => {
                var newapplication = {application:{...application, extNavPath:[this.currentRoute,lastSavedRoute, index]}}
                return this.gloalService.putApplicantProfile(newapplication).pipe(
                    map((application: any) => {
                        application = application.application;
                        this.appFacade.setApplicationID(application.applicationId);
                        this.appFacade.setLobType(application.lobType);
                        (application.extNavPath) ? application.extNavPath = application.extNavPath : application.extNavPath = ['profile', 'application', ''];
                       // this.appFacade.getLastSavedRoute(application.extNavPath);
                        delete application['extNavPath'];
                        return AppActions.getBsaProfileSuccess({ application })
                    }),
                    catchError((error) => of(AppActions.getBsaProfileFailure({ error })))
                )
            })
        );
    });
}


