import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'sanitize' })
export class SanitizePipe implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) { }
    transform(url: string) {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
        // return this.domSanitizer.sanitize(SecurityContext.URL, url);
    }
}