import { Injectable } from '@angular/core';
import { ApiHttpService } from '@app/core/services/api-http.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class EstateService {
  onboardUrl = `${environment.coreServiceUrl}v1/commercial/on-board/application`;
  constructor(private apiHttpService: ApiHttpService) {}

  saveEstateRelatedQsn(estateData: any) {
    if (estateData.estatesInfoId) {
      return this.apiHttpService.put(`${this.onboardUrl}/${estateData.applicationId}/applicant/estates`, estateData);
    } else {
      return this.apiHttpService.post(`${this.onboardUrl}/${estateData.applicationId}/applicant/estates`, estateData);
    }
  }

  getEstateData(applicationId: any) {
    return this.apiHttpService.get(`${this.onboardUrl}/${applicationId}/applicant/estates`);
  }

  deleteEstateData(estatesCustomerId: any, applicationId: any) {
    return this.apiHttpService.delete(`${this.onboardUrl}/${applicationId}/applicant/estates`);
  }
}
