import { Component, OnInit } from '@angular/core';
import { AdditionalFooterLinks, FooterLinks } from 'src/assets/links/footer-links';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  footerLinks: any = FooterLinks;
  additionalLinks: any = AdditionalFooterLinks;

  constructor() { }

  ngOnInit() {}

}
