import { Component, OnInit } from '@angular/core';
import { AppFacade } from '@app/core';
import { Router } from '@angular/router';
import { SIZE_TO_MEDIA } from '@ionic/core/dist/collection/utils/media';
import { AuthService } from '@app/core';
import { AuthGuardService } from '@app/core';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent implements OnInit {
  isCollpased: boolean = true;
  userInfo$ = this.appFacade.getUserInfo$;
  constructor(private router: Router, private appFacade: AppFacade,private _authService:AuthService, private ag: AuthGuardService) { }

  ngOnInit() {}

  toggleMenu() {
    const splitPane = document.querySelector('ion-split-pane');
    if (window.matchMedia(SIZE_TO_MEDIA[splitPane.when] || splitPane.when).matches)
      splitPane.classList.toggle('split-pane-visible')
  }

}
