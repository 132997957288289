export const apartmentIdentifierPossibleValues = [
  { key: 'apartment', itemName: 'APT' },
  { key: 'slip', itemName: 'SLIP' },
  { key: 'suite', itemName: 'STE' },
  { key: 'unit', itemName: 'UNIT' },
  { key: 'building', itemName: 'BLDG' },
  { key: 'department', itemName: 'DEPT' },
  { key: 'floor', itemName: 'FL' },
  { key: 'hanger', itemName: 'HNGR' },
  { key: 'key', itemName: 'KEY' },
  { key: 'lot', itemName: 'LOT' },
  { key: 'office', itemName: 'OFC' },
  { key: 'penthouse', itemName: 'PH' },
  { key: 'pier', itemName: 'PIER' },
  { key: 'room', itemName: 'RM' },
  { key: 'space', itemName: 'SPC' },
  { key: 'stop', itemName: 'STOP' },
  { key: 'trailer', itemName: 'TRLR' },
  { key: 'noIdentifier', itemName: 'NONE'}
];

export function getApartmentNumberIdentifierKey(apartmentNumberId: string) {
  return apartmentIdentifierPossibleValues.find(item => item.itemName.toLocaleLowerCase()
  === apartmentNumberId?.toLocaleLowerCase())?.key || apartmentNumberId;
}

export function getApartmentIdentifierValue(apartmentNumberId: string) {
  return apartmentIdentifierPossibleValues.find(item => item.key.toLocaleLowerCase() ===
    apartmentNumberId?.toLocaleLowerCase())?.itemName || apartmentNumberId;
}


