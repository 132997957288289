import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { AppState } from "@app/core";
import { appReducer, APP_STATE } from "./app.reducer";

export const reducers: ActionReducerMap<AppState> = {
  [APP_STATE]: appReducer
};

export const metaReducers: MetaReducer<AppState>[] = [];


