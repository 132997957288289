import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conditions',
  templateUrl: './conditions.component.html',
  styleUrls: ['./conditions.component.scss'],
})
export class ConditionsComponent implements OnInit {

  conditions: any[] = [
    {
      title: 'TO APPLY FOR AN ACCOUNT YOU MUST BE',
      conditions: [
        'A U.S. Citizen or Resident Alien, at least 18 years old',
        'A signer authorized to open accounts on behalf of the business',
        'A control person for this business'
      ]
    },
    {
      title: 'YOU WILL ALSO NEED TO HAVE',
      conditions: [
        'Legal Documents for the business',
        'Personal information for any beneficial owner and/or additional account signer',
        'A government issued ID'
      ]
    }
  ];

  constructor() { }

  ngOnInit() {}

}
