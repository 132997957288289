import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiHttpService } from "./api-http.service";
import { ApplicationFlow } from '@app/models/application-flow';
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class QuestionnaireService {
  commercialUrl = `${environment.coreServiceUrl}v1/commercial/on-board/application`;
  applicationFlow = ApplicationFlow;
  constructor(private apihttpservice: ApiHttpService) { }
  getQuestions (appId: any) {
    return this.apihttpservice.get(this.commercialUrl + '/' + appId + '/profile/2022')
  }

  getAnswers (appId) {
    return this.apihttpservice.get(this.commercialUrl + '/' + appId + '/applicant/profile')
  }

  loadAccountQuestions(appId: any) {
    return this.apihttpservice.get(this.commercialUrl + '/' + appId + '/profile/account/all/2022');
  }

 getAccountAnswers(applicationId: number) {
    return this.apihttpservice.get(
      this.commercialUrl + `/${applicationId}/profile/account`
    );
  }
  postAnswers(data, view, appId) {
    var category;
    if(view === this.applicationFlow.businessProfile) {category = 'business'}
    if(view === this.applicationFlow.geography) {category = 'geography'}
    if(view === this.applicationFlow.regulatoryLegal) {category = 'regulatory'}
    if(view === this.applicationFlow.MBS_TBA) {category = 'mbs-tba'}
    if(view === this.applicationFlow.securities) {category = 'securities'}
    if(view === this.applicationFlow.additionalQuestions) {category = 'additional-business'}
    if(view === this.applicationFlow.estateRelatedQuestions) {category = 'estates'}
    if(view === this.applicationFlow.trustRelatedQuestions) {category = 'trust'}
    if(category !== 'estates' && category !== 'trust' && category !== 'mbs-tba' && category !== 'securities') {
      return this.apihttpservice.post(this.commercialUrl + `/${appId}/applicant/profile/${category}`, data)
    } else if(category === 'mbs-tba') {
      data = { 
        ...data,
        lobType: 'Investment_Banking',
        profileType: 'MBS_TBA',
      };
      const requestOptions: Object = {
        headers: new HttpHeaders().set('Profiletype', 'MBS_TBA').set('Lobtype', 'MBS_TBA')
      }
      return this.apihttpservice.post(this.commercialUrl + `/${appId}/applicant/profile`, data, requestOptions);
    } else if( category === 'securities') {
      data = { 
        ...data,
        lobType: 'Investment_Banking',
        profileType: 'security'
      };
      const requestOptions: Object = {
        headers: new HttpHeaders().set('Profiletype', 'security').set('Lobtype', 'security')
      }
      return this.apihttpservice.post(this.commercialUrl + `/${appId}/applicant/profile`, data, requestOptions);
    }
  }
  postAccountAnswers(url, data, appId) {
    return this.apihttpservice.post(this.commercialUrl + `/${appId}/applicant/profile/${url}`, data)
  }
}

